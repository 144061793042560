import React, { useEffect, useState } from "react";
// import './App.css';
import "./history.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { BASE_API_URL, BASE_API_URL1 } from "./content";
import "share-api-polyfill";
import { preventReload } from './content.js';
import LineChart from "./component/LineChart.js";
import Plot from 'react-plotly.js';

const History = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [hasData, setHasData] = useState(false);


  const [vitalSigns, setVitalSigns] = useState([]);
  const [guid, setguid] = useState(localStorage.getItem("guid"));
  const [gender, setGender] = useState(localStorage.getItem("gender"));
  const [age, setAge] = useState(localStorage.getItem("about"));
  const [height, setHeight] = useState(localStorage.getItem("height"));
  const [weight, setWeight] = useState(localStorage.getItem("weight"));
  const [modal1IsOpen, setModal1IsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [weighttype, setweighttype] = useState(
    localStorage.getItem("weighttype")
  );
  const [heighttype, setheighttype] = useState(
    localStorage.getItem("heighttype")
  );
  const [feet, setfeet] = useState(localStorage.getItem("feet"));
  const [inches, setinches] = useState(localStorage.getItem("inches"));

  const [HR, setHR] = useState(localStorage.getItem("0"));
  const [Blood1, setBloodp] = useState(localStorage.getItem("0"));
  const [Temperature, setTemp] = useState(localStorage.getItem("0"));
  const [oxygen, setOxyzen] = useState(localStorage.getItem("0"));
  const [Respiration, setResp] = useState(localStorage.getItem("0"));
  const [bmi, setBMI] = useState(localStorage.getItem("0"));
  const [qt, setQt] = useState(localStorage.getItem("0"));
  const [Qrs, setQrs] = useState(localStorage.getItem("0"));
  const [ST, setSt] = useState(localStorage.getItem("0"));
  const [Pr, setPr] = useState(localStorage.getItem("0"));
  const [date, setDate] = useState(localStorage.getItem("0"));
  const [time, setTime] = useState(localStorage.getItem("0"));

  const [scrollX, setScrollX] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isECGPopupOpen,setIsECGPopupOpen] = useState(false);
  const [isECGPopupOpen1,setIsECGPopupOpen1] = useState(false);
  const [currentInfo, setCurrentInfo] = useState("");
  const [currentInfoName, setCurrentInfoName] = useState("");
  const [currentInfo1, setCurrentInfo1] = useState("");
  const [currentInfo2, setCurrentInfo2] = useState("");


  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem("userPhoneNumber"));


  // const filterListTitle = ['HR', 'BP', 'QT', 'PR', 'O2 Level',
  // 'Body Temp', 'Respiration', 'BMI', 'ST', 'QRS'];

  const [isOn, setIsOn] = useState(false);

  const handleToggle = () => {
    setIsOn(!isOn);
  };

  const containerClassName = `toggle-container ${isOn ? 'on' : 'off'}`;
  const buttonClassName = `toggle-button ${isOn ? 'on' : 'off'}`;


  const [chartData, setChartData] = useState({
    labels: ['a', 'b', 'c', 'd', 'e'],
    data: [23, 34, 56, 78, 45],
    label: "Line Chart"
  });

  const LoadingSpinner = () => {
    return (
      <div className="history-loading-spinner">
        <div className="history-spinner"></div>
      </div>
    );
  };

  useEffect(() => {
    setIsLoading(true);
    // Fetch vital signs data here
    callVitalsHistoryOnLoad();
  }, []);


  const selecteKey = [
    "heartRate",
    "bodyTemprature",
    "respirationRate",
    "bloodPressure",
    "oxygenLevel",
    "bodyMassIndex",
    "qtinterval",
    "stinterval",
    "printerval",
    "qrsinterval",
  ];

  const selectKeyUnits = [
    "bpm",
    "°F",
    "BRPM",
    "mmHg",
    "%",
    " ",
    "sec",
    "sec",
    "sec",
    "sec",
  ];

  const filterListTitle = [
    "Heart Rate",
    "Body Temprature",
    "Respiration Rate",
    "Blood Pressure",
    "Oxygen Level",
    "Body Mass Index",
    "ECG QT",
    "ECG ST",
    "ECG PR",
    "ECG QRS",
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("All");
  const options = [
    "All",
    "Heart Rate",
    "Body Temprature",
    "Respiration Rate",
    "Blood Pressure",
    "Oxygen Level",
    "Body Mass Index",
    "ECG QT",
    "ECG ST",
    "ECG PR",
    "ECG QRS",
  ];

  const toggleModal = () => {
    setIsOpen(!isOpen);
    setIsRotated(!isRotated);
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    setIsRotated(!isRotated);
    setIsOn(false);

    if (selectedValue === value || value === "All") {
      // setSelectedOptions(selectedOptions.filter(option => option !== value));
      setSelectedValue("All");
      setIsFilterON(false);
    } else {
     // setSelectedOptions([...selectedOptions, value]);
     setSelectedValue(value);
     setIsFilterON(true);
     setSelectedKey(selecteKey[options.indexOf(value) - 1]);
     setSelectedKeyUnits(selectKeyUnits[options.indexOf(value) - 1]);
     // setSelectedKeyUnits(selectKeyUnits[event.target.selectedIndex - 1])

     const singleVitalArray = vitalSigns.map((vitals) => vitals[selecteKey[options.indexOf(value) - 1]]);
     const vitalDate = vitalSigns.map((vitals) => vitals.onlyDate+'-'+vitals.onlyTime);

     console.log(value, 'DATA', vitalDate, 'vitals array', singleVitalArray);

     if (value === "Blood Pressure") {
       var sysandia = singleVitalArray.map((bp) => bp.split('/') );
       console.log('sysdia',sysandia );
       const systolic = sysandia.map(i => i[0]);
       const diastolic = sysandia.map(i => i[1]);

       setChartData([{
         labels: vitalDate.reverse(),
         data:systolic.reverse(),
         label: "systolic (mmHg)"
       },
       {
         // labels: vitalDate,
         data: diastolic.reverse(),
         label: "diastolic (mmHg)"
       }]);

     } else {
       setChartData([{
         labels: vitalDate.reverse(),
         data: singleVitalArray.reverse(),
         label: (selectKeyUnits[options.indexOf(value) - 1])===" "? value+' ':value+' ('+selectKeyUnits[options.indexOf(value) - 1]+')'
       }]);
     }

    }
    setIsOpen(false);
  };

  window.onload = () => {
    // setShowPopup(true);
    Navigate("/history");
  };
  const [isFilterON, setIsFilterON] = useState(false);
  // const [selectedValue, setSelectedValue] = useState('');
  const [selectedKey, setSelectedKey] = useState("");
  const [selectedKeyUnits, setSelectedKeyUnits] = useState("");

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    console.log("index", event.target.selectedIndex);
    if (event.target.selectedIndex === 0) {
      setIsFilterON(false);
    } else {
      setIsFilterON(true);
      setSelectedKey(selecteKey[event.target.selectedIndex - 1]);
      setSelectedKeyUnits(selectKeyUnits[event.target.selectedIndex - 1]);
    }
    setSelectedValue(newValue);
  };

  const proxyURL = "https://cors-anywhere.herokuapp.com/"; //${proxyURL}
  const loginUrl = `${BASE_API_URL1}PatientVitalSigns/GetDetails_V2`;
  // const rectangles = Array(length).fill(null);

  // const historyFilterBoldTextStyle = {
  //   fontSize: '16px',
  // };

  const historyHeaderStyle = {
    backgroundColor: "navy",
    color: "white",
    padding: "20px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "35px",
  };

  const historyRectangleStyle = {
    width: "460px", // Make the width 100% for mobile
    height: "270px",
    backgroundColor: "white",
    margin: "10px auto",
    display: "flex",
    flexDirection: "column",
  };

  const historyTopContentStyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
  };

  const historyBottomContentStyle = {
    marginTop: "1px",
    padding: "10px",
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
  };

  const historyBoldTextStyle = {
    fontWeight: "bold",
  };

  const modalStyle = {
    // display: isModalOpen ? 'block' : 'none',
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "navy",
    zIndex: 1000,
    width: "20em",
    height: "8.5em",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "10px",
    textAlign: "center",
    background: `linear-gradient(to bottom, navy 3.3em, white 3.3em)`,
  };

  const handleScrollX = (scroll) => {
    const container = document.getElementById("ecg-container");
    if (container) {
      const maxScrollX = container.scrollWidth - container.clientWidth;
      setScrollX(Math.min(maxScrollX, Math.max(0, scroll)));
    }
  };

  const handleScrollY = (scroll) => {
    const container = document.getElementById("ecg-container");
    if (container) {
      const maxScrollY = container.scrollHeight - container.clientHeight;
      setScrollY(Math.min(maxScrollY, Math.max(0, scroll)));
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setIsECGPopupOpen(false);
    setIsECGPopupOpen1(false);
  };

  useEffect(() => {



    preventReload();

    handleScrollX(scrollX);
    handleScrollY(scrollY);
  }, [scrollX, scrollY]);



  const Info = [
    "Blood pressure is the force exerted by circulating blood against the walls of the arteries.",
    "Oxygen saturation is a measure of the percentage of hemoglobin in the blood that is bound to oxygen.",
    "Heart rate is the number of times the heart beats per minute, measuring the rhythm of the cardiac cycle.",
    "Body temperature is the measure of the internal heat level of the human body.",
    "Respiration rate refers to the number of breaths taken per minute.",
    "Body Mass Index (BMI) is a measure of body fat based on a person's weight and height.",
    "QT interval represents the duration of ventricular depolarization and repolarization.",
    "ST segment is the portion of the ECG between the QRS complex and the T wave, reflecting the early part of ventricular repolarization.",
    "PR interval measures the time from the onset of atrial depolarization (P wave) to the onset of ventricular depolarization (QRS complex).",
    "QRS interval represents the time it takes for ventricular depolarization to occur.",
  ];

  const Info1 = [
    "120/80 - 140/90 mmHg",
    "95% to 100%",
    "60 - 90 bpm",
    "96 - 98.4 F",
    "12 - 20 BRPM",
    "18.5 - 25",
    "0.35 - 0.46 Sec",
    "0.08 - 0.12Sec",
    "0.12 - 0.20 Sec",
    "0.06 - 0.10 Sec"
  ];

  const Info2 = [
    Blood1 + " mmHg",
    oxygen + "%",
    HR + " bpm",
    Temperature + "F",
    Respiration + " BRPM",
    bmi,
    qt + " Sec",
    ST + " Sec",
    Pr + " Sec",
    Qrs + " Sec",
  ];

  const InfoName = [
    "Blood Pressure",
    "Oxygen Saturation",
    "Heart Rate",
    "Body Temperature",
    "Respiration Rate",
    "Body Mass Index (BMI)",
    "QT Interval",
    "ST Segment",
    "PR Interval",
    "QRS Interval",
  ];

  const patientData = [
    {
      patientVitalSignsID: 1064,
      patientGUID: "f44ea07e-8af0-4485-b26c-b5f10cd677dc",
      vitalSignType: "All",
      heartRate: 84,
      bloodPressure: "137/84",
      respirationRate: 12,
      oxygenLevel: 95,
      bodyTemprature: 96.0,
      recordingDateTime: "2023-09-23T19:36:00",
      recordingDateTimeview: null,
      onlyDate: "23/09/2023",
      onlyTime: "19:36",
      bodyMassIndex: "21.68",
      qtinterval: "0.75 Sec",
      stinterval: "0.08 Sec",
      printerval: "0.1 Sec",
      qrsinterval: "0.05 Sec",
      createdDateTime: "2023-09-23T19:36:58.817",
      vtype: null,
      insert_Type: "Mobile",
      count: null,
      age: 25,
    },
    {
      patientVitalSignsID: 1064,
      patientGUID: "f44ea07e-8af0-4485-b26c-b5f10cd677dc",
      vitalSignType: "All",
      heartRate: 84,
      bloodPressure: "137/84",
      respirationRate: 12,
      oxygenLevel: 95,
      bodyTemprature: 96.0,
      recordingDateTime: "2023-09-23T19:36:00",
      recordingDateTimeview: null,
      onlyDate: "23/09/2023",
      onlyTime: "19:36",
      bodyMassIndex: "21.68",
      qtinterval: "0.75 Sec",
      stinterval: "0.08 Sec",
      printerval: "0.1 Sec",
      qrsinterval: "0.05 Sec",
      createdDateTime: "2023-09-23T19:36:58.817",
      vtype: null,
      insert_Type: "Mobile",
      count: null,
      age: 25,
    },
    {
      patientVitalSignsID: 1064,
      patientGUID: "f44ea07e-8af0-4485-b26c-b5f10cd677dc",
      vitalSignType: "All",
      heartRate: 84,
      bloodPressure: "137/84",
      respirationRate: 12,
      oxygenLevel: 95,
      bodyTemprature: 96.0,
      recordingDateTime: "2023-09-23T19:36:00",
      recordingDateTimeview: null,
      onlyDate: "23/09/2023",
      onlyTime: "19:36",
      bodyMassIndex: "21.68",
      qtinterval: "0.75 Sec",
      stinterval: "0.08 Sec",
      printerval: "0.1 Sec",
      qrsinterval: "0.05 Sec",
      createdDateTime: "2023-09-23T19:36:58.817",
      vtype: null,
      insert_Type: "Mobile",
      count: null,
      age: 25,
    },
    {
      patientVitalSignsID: 1064,
      patientGUID: "f44ea07e-8af0-4485-b26c-b5f10cd677dc",
      vitalSignType: "All",
      heartRate: 84,
      bloodPressure: "137/84",
      respirationRate: 12,
      oxygenLevel: 95,
      bodyTemprature: 96.0,
      recordingDateTime: "2023-09-23T19:36:00",
      recordingDateTimeview: null,
      onlyDate: "23/09/2023",
      onlyTime: "19:36",
      bodyMassIndex: "21.68",
      qtinterval: "0.75 Sec",

      stinterval: "0.08 Sec",
      printerval: "0.1 Sec",
      qrsinterval: "0.05 Sec",
      createdDateTime: "2023-09-23T19:36:58.817",
      vtype: null,
      insert_Type: "Mobile",
      count: null,
      age: 25,
    },
  ];

  const overlayStyle = {
    display: isOpen ? "block" : "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "1000vh",
    backgroundColor: "rgba(0, 0, 0, 0.2)", // Semi-transparent black background
    zIndex: 999, // Lower z-index to be below the modal
  };

  const [isRotated, setIsRotated] = useState(false);
  const rotation = isRotated ? 180 : 0;

  const imgStyle = {
    position: "absolute",
    marginTop: "0.25em",
    right: "2em",
    // height: '0.7em',
    width: "0.7em",
    transform: `rotate(${rotation}deg)`,
  };

  const heartbeat = parseInt(HR);
  const x1 = 60/heartbeat;
  const y1 = 1.00+parseFloat(qt)*parseFloat(Qrs);
  // Given x and y data
  const data = [
    { x:x1* 0, y: y1*0 },
    { x:x1* 0.1, y:y1* 0.05 },
    { x:x1* 0.12, y:y1* 0.1 },
    { x:x1* 0.2, y:y1* 0.05 },
    { x:x1* 0.21, y:y1* 0 },
    { x:x1* 0.26, y:y1* 1 },
    { x:x1* 0.31, y:y1* -0.18 },
    { x:x1* 0.35, y:y1* 0 },
    { x:x1* 0.4, y:y1* 0 },
    { x:x1* 0.45, y:y1* 0.05 },
    { x:x1* 0.5, y:y1* 0.06 },
    { x:x1* 0.53, y:y1* 0.07 },
    { x:x1* 0.58, y:y1* 0.06 },
    { x:x1* 0.6, y:y1* 0.05 },
    { x:x1* 0.65, y:y1* 0 },
    { x:x1* 0.68, y:y1* 0 },
    { x:x1* 0.7, y:y1* 0 },
    { x:x1* 0.75, y:y1* 0 },
    { x:x1* 0.78, y:y1* 0.01 },
    { x:x1* 0.8, y:y1* 0.02 },
    { x:x1* 0.82, y:y1* 0.03 },
    { x:x1* 0.85, y:y1* 0.02 },
    { x:x1* 0.86, y:y1* 0.01 },
    { x:x1* 0.88, y:y1* 0 },
    { x:x1* 0.9, y:y1* 0 },
    { x:x1* 0.9, y:y1* 0 },
    { x:x1* 0.92, y:y1* 0 },
    { x:x1* 0.95, y:y1* 0 },
    { x:x1* 1, y: y1*0.1 },
    { x:x1* 1.1, y:y1* 0.05 },
    { x:x1* 1.15, y:y1* 0.1 },
    { x:x1* 1.16, y:y1* 0.05 },
    { x:x1* 1.17, y:y1* 0 },
    { x:x1* 1.25, y:y1* 1 },
    { x:x1* 1.3, y:y1* -0.18 },
    { x:x1* 1.4, y:y1* 0 },
    { x:x1* 1.55, y:y1* 0 },
    { x:x1* 1.65, y:y1* 0.05 },
    { x:x1* 1.71, y:y1* 0.06 },
    { x:x1* 1.75, y:y1* 0.07 },
    { x:x1* 1.78, y:y1* 0.06 },
    { x:x1* 1.79, y:y1* 0.05 },
    { x:x1* 1.8, y:y1* 0.0 },
    { x:x1* 2, y: y1*0.0 },
    { x:x1* 2.1, y:y1* 0.05 },
    { x:x1* 2.12, y:y1* 0.1 },
    { x:x1* 2.2, y:y1* 0.05 },
    { x:x1* 2.21, y:y1* 0 },
    { x:x1* 2.26, y:y1* 1 },
    { x:x1* 2.31, y:y1* -0.18 },
    { x:x1* 2.35, y:y1* 0 },
    { x:x1* 2.4, y:y1* 0 },
    { x:x1* 2.45, y:y1* 0.05 },
    { x:x1* 2.5, y:y1* 0.06 },
    { x:x1* 2.53, y:y1* 0.07 },
    { x:x1* 2.58, y:y1* 0.06 },
    { x:x1* 2.6, y:y1* 0.05 },
    { x:x1* 2.65, y:y1* 0 },
    { x:x1* 2.68, y:y1* 0 },
    { x:x1* 2.7, y:y1* 0 },
    { x:x1* 2.75, y:y1* 0 },
    { x:x1* 2.78, y:y1* 0.01 },
    { x:x1* 2.8, y:y1* 0.02 },
    { x:x1* 2.82, y:y1* 0.03 },
    { x:x1* 2.85, y:y1* 0.02 },
    { x:x1* 2.86, y:y1* 0.01 },
    { x:x1* 2.88, y:y1* 0 },
    { x:x1* 2.9, y:y1* 0 },
    { x:x1* 2.9, y:y1* 0 },
    { x:x1* 2.92, y:y1* 0 },
    { x:x1* 2.95, y:y1* 0 },
    { x:x1* 3, y: y1*0.1 },
    { x:x1* 3.1, y:y1* 0.05 },
    { x:x1* 3.15, y:y1* 0.1 },
    { x:x1* 3.16, y:y1* 0.05 },
    { x:x1* 3.17, y:y1* 0 },
    { x:x1* 3.25, y:y1* 1 },
    { x:x1* 3.3, y:y1* -0.18 },
    { x:x1* 3.4, y:y1* 0 },
    { x:x1* 3.55, y:y1* 0 },
    { x:x1* 3.65, y:y1* 0.05 },
    { x:x1* 3.71, y:y1* 0.06 },
    { x:x1* 3.75, y:y1* 0.07 },
    { x:x1* 3.78, y:y1* 0.06 },
    { x:x1* 3.79, y:y1* 0.05 },
    { x:x1* 3.8, y:y1* 0.0 },
    { x:x1* 4, y: y1*0.1 },
    { x:x1* 4.1, y:y1* 0.05 },
    { x:x1* 4.15, y:y1* 0.1 },
    { x:x1* 4.16, y:y1* 0.05 },
    { x:x1* 4.17, y:y1* 0 },
    { x:x1* 4.25, y:y1* 1 },
    { x:x1* 4.3, y:y1* -0.18 },
    { x:x1* 4.4, y:y1* 0 },
    { x:x1* 4.55, y:y1* 0 },
    { x:x1* 4.65, y:y1* 0.05 },
    { x:x1* 4.71, y:y1* 0.06 },
    { x:x1* 4.75, y:y1* 0.07 },
    { x:x1* 4.78, y:y1* 0.06 },
    { x:x1* 4.79, y:y1* 0.05 },
    { x:x1* 4.8, y:y1* 0.0 },
    { x:x1* 5, y: y1*0.1 },
    { x:x1* 5.1, y:y1* 0.05 },
    { x:x1* 5.15, y:y1* 0.1 },
    { x:x1* 5.16, y:y1* 0.05 },
    { x:x1* 5.17, y:y1* 0 },
    { x:x1* 5.25, y:y1* 1 },
    { x:x1* 5.3, y:y1* -0.18 },
    { x:x1* 5.4, y:y1* 0 },
    { x:x1* 5.55, y:y1* 0 },
    { x:x1* 5.65, y:y1* 0.05 },
    { x:x1* 5.71, y:y1* 0.06 },
    { x:x1* 5.75, y:y1* 0.07 },
    { x:x1* 5.78, y:y1* 0.06 },
    { x:x1* 5.79, y:y1* 0.05 },
    { x:x1* 5.8, y:y1* 0.0 },
    { x:x1* 6, y: y1*0.0 },
  ];

  const layout = {
    title: 'Electrocardiogram',
    xaxis: {
      title: 'Time (s)',
      showgrid: true,
      zeroline: true,
      range:[0,x1*2],
      dtick:0.25
    },
    yaxis: {
      title: '(Milli-Volts)',
      showgrid: true,
      zeroline: true,
      range:[-0.5,2],
      dtick:0.25
    },

    margin: {
      l: 65, // left margin
      r: 40, // right margin
      b: 50, // bottom margin
      t: 60, // top margin
      pad: 20, // padding around the plot area
    },
    width: 370 , // Set the width of the chart in pixels
    height: 330, // Set the height of the chart in pixels
  };

  const trace = {
    x: data.map((point) => point.x),
    y: data.map((point) => point.y),
    type: 'scatter',
    mode: 'lines',
    name: 'ECG Waveform',
    line: { shape: 'linear' },
    marker: { color: 'Navy' },
    hoverinfo:'none',
  };

  useEffect(() => {
    setguid(localStorage.getItem("guid"));
    setGender(localStorage.getItem("gender"));
    setHeight(localStorage.getItem("height"));
    setWeight(localStorage.getItem("weight"));

    setfeet(localStorage.getItem("feet"));
    setinches(localStorage.getItem("inches"));
    setweighttype(localStorage.getItem("weighttype"));
    setheighttype(localStorage.getItem("heighttype"));
    setPhoneNumber(localStorage.getItem("userPhoneNumber"));

    // setVitalSigns(patientData);

    callVitalsHistoryOnLoad();
  }, []);

  var requestBody = {
    PatientGUID: guid,
    // PatientGUID: "f44ea07e-8af0-4485-b26c-b5f10cd677dc"
  };

  const handleItemClick = (index) => {
    setModalIsOpen(true);
    const data = vitalSigns[index];
    setBMI(data.bodyMassIndex);
    setBloodp(data.bloodPressure);
    setHR(data.heartRate);
    setOxyzen(data.oxygenLevel);
    setResp(data.respirationRate);
    setTemp(data.bodyTemprature);
    setPr(data.printerval);
    setQrs(data.qrsinterval);
    setQt(data.qtinterval);
    setSt(data.stinterval);
    setDate(data.onlyDate);
    setTime(data.onlyTime);
    setIsModalOpen(true);
  };

  function gettheText(obj) {
    var text = `PhoneNumber: ${phoneNumber}
Date: ${obj.onlyDate}
Time: ${obj.onlyTime}
HeartRate: ${obj.heartRate} bpm
Body Temprature: ${obj.bodyTemprature}°F
Respiration Rate: ${obj.respirationRate} BRPM
Blood Pressure: ${obj.bloodPressure} mmHg
Oxygen Level: ${obj.oxygenLevel}%
Body Mass Index: ${obj.bodyMassIndex}
ECG QT: ${obj.qtinterval} sec
ECG ST: ${obj.stinterval} sec
ECG PR: ${obj.printerval} sec
ECG QRS: ${obj.qrsinterval} sec`;
    return text;
  }

  // const callVitalsHistoryOnLoad = () => {
  //   console.log("Function called when component is loaded");
  //   console.log("requesting", requestBody);

  //   axios
  //     .post(loginUrl, requestBody, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((response) => {
  //       console.log("data", response.data);
  //       var data = response.data.errormessage;
  //       response.data.forEach(element => {
  //         element.qtinterval = element.qtinterval.replace("Sec","");
  //         element.printerval = element.printerval.replace("Sec","");
  //         element.stinterval = element.stinterval.replace("Sec","");
  //         element.qrsinterval = element.qrsinterval.replace("Sec","");
  //       });
  //       setVitalSigns(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error:1111", error);
  //     });
  // };

  const callVitalsHistoryOnLoad = () => {
    console.log("Function called when component is loaded");
    console.log("requesting", requestBody);

    setIsLoading(true);

    axios
      .post(loginUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("data", response.data);
        var data = response.data.errormessage;
        response.data.forEach(element => {
          element.qtinterval = element.qtinterval.replace("Sec", "");
          element.printerval = element.printerval.replace("Sec", "");
          element.stinterval = element.stinterval.replace("Sec", "");
          element.qrsinterval = element.qrsinterval.replace("Sec", "");
        });

        setVitalSigns(response.data);
        setHasData(response.data.length > 0);
      })
      .catch((error) => {
        console.error("Error:1111", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };



  const Navigate = useNavigate();
  const backButton = () => {
    Navigate("/Home-Page");
  };

  const ECGContainer = {
    display: isModalOpen ? "block" : "none",
    scrollLeft: scrollX,
    scrollTop: scrollY, // Set scrollTop to scrollY
  };

  const svgStyle = {
    display: "block",
    margin: "auto",
  };

  const numVerticalLines = 8;
  const HorizontalLineSpacing = ((373 - 50) / (numVerticalLines - 1)) * 1.8;
  const verticalLineSpacing = ((180 - 50) / (numVerticalLines - 1)) * 1.8;

  const heading = {
    backgroundColor: "navy",
    color: "white",
    padding: "20px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "35px",
  };

  const verticalLines = [];
  for (let i = 0; i < numVerticalLines; i++) {
    const x = 50 + i * verticalLineSpacing;
    const color = i === 7 ? "black" : "gray"; // Change color to black for the 8th line
    verticalLines.push(
      <line
        key={i}
        x1={x}
        y1={50}
        x2={x}
        y2={250}
        stroke={color}
        strokeWidth="1"
      />
    );
  }

  const RR=parseFloat(localStorage.getItem('qt'));
  // ECG data (sample data, replace with your actual data)
  const ecgData = [
    // ECG data (sample data, replace with your actual data)
      { x: 0, y: RR+0 },
      { x: 0.1, y: RR+0.05 },
      { x: 0.12, y:RR+ 0.1 },
      { x: 0.2, y: RR+0.05 },
      { x: 0.21, y: RR+0 },
      { x: 0.26, y: RR },
      { x: 0.31, y: RR-0.17 },
      { x: 0.35, y: RR+0 },
      { x: 0.4, y: RR+0 },
      { x: 0.45, y: RR+0.05 },
      { x: 0.5, y: RR+0.06 },
      { x: 0.53, y:RR+ 0.07 },
      { x: 0.58, y: RR+0.06 },
      { x: 0.6, y: RR+0.05 },
      { x: 0.65, y: RR+0 },
      { x: 0.68, y: RR+0 },
      { x: 0.7, y: RR+0 },
      { x: 0.75, y: RR+0 },
      { x: 0.78, y: RR+0.01 },
      { x: 0.8, y: RR+0.02 },
      { x: 0.82, y: RR+0.03 },
      { x: 0.85, y: RR+0.02 },
      { x: 0.86, y: RR+0.01 },
      { x: 0.88, y: RR+0 },
      { x: 0.9, y: RR },
      { x: 0.9, y: RR },
      { x: 0.92, y: RR+0 },
      { x: 0.95, y: RR+0 },
      { x: 1, y: RR+0.1 },
      { x: 1.1, y: RR+0.05 },
      { x: 1.15, y: RR+0.1 },
      { x: 1.16, y: RR+0.05 },
      { x: 1.17, y: RR+0 },
      { x: 1.25, y: RR+1 },
      { x: 1.3, y: RR-0.17 },
      { x: 1.4, y: RR+0 },
      { x: 1.55, y: RR+0 },
      { x: 1.65, y: RR+0.05 },
      { x: 1.71, y: RR+0.06 },
      { x: 1.75, y: RR+0.07 },
      { x: 1.78, y: RR+0.06 },
      { x: 1.79, y: RR+0.05 },
      { x: 1.8, y: RR+0.0 },
      { x: 2, y: RR+0.1 },
      { x: 2.1, y: RR+0.05 },
      { x: 2.12, y:RR+ 0.1 },
      { x: 2.2, y: RR+0.05 },
      { x: 2.21, y: RR+0 },
      { x: 2.26, y: RR },
      { x: 2.31, y: RR-0.17 },
      { x: 2.35, y: RR+0 },
      { x: 2.4, y: RR+0 },
      { x: 2.45, y: RR+0.05 },
      { x: 2.5, y: RR+0.06 },
      { x: 2.53, y:RR+ 0.07 },
      { x: 2.58, y: RR+0.06 },
      { x: 2.6, y: RR+0.05 },
      { x: 2.65, y: RR+0 },
      { x: 2.68, y: RR+0 },
      { x: 2.7, y: RR+0 },
      { x: 2.75, y: RR+0 },
      { x: 2.78, y: RR+0.01 },
      { x: 2.8, y: RR+0.02 },
      { x: 2.82, y: RR+0.03 },
      { x: 2.85, y: RR+0.02 },
      { x: 2.86, y: RR+0.01 },
      { x: 2.88, y: RR+0 },
      { x: 2.9, y: RR },
      { x: 2.9, y: RR },
      { x: 2.92, y: RR+0 },
      { x: 2.95, y: RR+0 },
      { x: 3, y: RR+0.1 },
      { x: 3.1, y: RR+0.05 },
      { x: 3.15, y: RR+0.1 },
      { x: 3.16, y: RR+0.05 },
      { x: 3.17, y: RR+0 },
      { x: 3.25, y: RR+1 },
      { x: 3.3, y: RR-0.17 },
      { x: 3.4, y: RR+0 },
      { x: 3.55, y: RR+0 },
      { x: 3.65, y: RR+0.05 },
      { x: 3.71, y: RR+0.06 },
      { x: 3.75, y: RR+0.07 },
      { x: 3.78, y: RR+0.06 },
      { x: 3.79, y: RR+0.05 },
      { x: 3.8, y: RR+0.0 },
      { x: 4, y: RR+0.1 },
    ];


  // Calculate the scaling factors based on chart dimensions and data range
  const xScale = (284 - 50) / (ecgData.length - 1);
  const yScale =
    (138 - 50) /
    (Math.max(...ecgData.map((point) => point.y)) -
      Math.min(...ecgData.map((point) => point.y)));

  // Convert ECG data to SVG path
  const ecgPath = `M${ecgData
    .map(
      (point, index) =>
        `${50 + index * xScale},${200 -
        (point.y - Math.min(...ecgData.map((point) => point.y))) * yScale
        }`
    )
    .join(" L")}`;

  return (
    <div>
      <header
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          padding: "18px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(Indian-Girls.jpg)`,
          zIndex: "999",
        }}
      >
        <img
          src="yourvitals_logo_panner.png"
          alt="yourVitals"
          style={{ width: "300px", height: "100px", marginRight: "1.5em" }}
        />
        <div style={{}}></div>
      </header>

      <div className="HistoryContainer">
        <header className="historyHeader">
          <button
            className="historyBackBtn"
            onClick={() => {
              backButton();
            }}
          >
            <img src="back.png" alt="Back" className="ImgHistoryBackBtn" />
            {/* Logout */}
          </button>

          <div className="HistoryHeaderTxt">VITAL SIGNS HISTORY</div>

        </header>

        <div className="profileDetailHeader">
          <div className="Historybioheadergrid">
            <div className="HistorybioheaderLeft-item">
              {/* <p> */}
              <span style={historyBoldTextStyle}>Gender:</span> {gender}
              {/* </p> */}
            </div>
            <div className="HistorybioheaderLeft-item">
              {/* <p> */}
              <span style={historyBoldTextStyle}>Height:</span> {heighttype === "CM"
                ? height + " cm"
                : feet + "'" + inches + '"'}


              {/* </p> */}
            </div>
            <div className="HistorybioheaderRight-item">
              {/* <p> */}
              <span style={historyBoldTextStyle}>Age:</span> {age}
              {/* </p> */}
            </div>
            <div className="HistorybioheaderRight-item">
              {/* <p> */}
              <span style={historyBoldTextStyle}>Weight:</span>  {weighttype == "KG" ? weight + " Kg" : weight + " lbs"}

              {/* </p> */}
            </div>
          </div>
        </div>

        {/* <div className='FilterDropdown'>
          <select className='select' value={selectedValue}
            onChange={handleInputChange}>
            <option value="">All</option>
            {filterListTitle.map((item, index) => (
              <option className='paragraphText' key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div> */}

        <button onClick={toggleModal} className="FilterHeaderTitle">
          {selectedValue}
          <img src="icons8-triangle-16.png" alt="Arrow" style={imgStyle} />
        </button>
        {isOpen && (
          <div style={overlayStyle}>
            <div className="filterModalBox">
              {/* <div className="modal-content"> */}

              <div
                style={{
                  background: "navy",
                  color: "white",
                  padding: "10px",
                  textAlign: "center",
                  fontSize: "16px",
                }}
              >
                Filter History
                <button
                  // className="close-button"
                  onClick={() => {
                    toggleModal(false);
                    setIsRotated(!isRotated);
                  }}
                  className="filterModelx"
                >
                  x
                </button>
              </div>

              <ul style={{ listStyleType: "none", padding: 0 }}>
                {options.map((option) => (
                  <li
                    key={option}
                    style={{
                      background: "#efefef",
                      marginBottom: "5px",
                      padding: "5px",
                    }}
                  >
                    <label style={{ textAlign: "left" }}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={selectedValue === option}
                        onChange={handleCheckboxChange}
                      />
                      <span style={{ marginLeft: "10px" }}>{option}</span>
                    </label>
                  </li>
                ))}
              </ul>
              {/* </div> */}
            </div>
          </div>
        )}

        {!isFilterON &&
          vitalSigns.map((vitalSign, index) => (
            <div
              key={index}
              className="history-rectangle"
              onClick={() => handleItemClick(index)}
            >
              <div className="history-top-content">
                <div style={{ flex: "auto" }}>
                  {!false && (
                    <p>
                      <span className="profileDetailText">Date:</span>{" "}
                      {vitalSign.onlyDate}
                    </p>
                  )}
                  <div style={{ marginTop: "-0.5em" }}>
                    <p>
                      <span className="profileDetailText">Time:</span>{" "}
                      {vitalSign.onlyTime}
                    </p>
                  </div>
                  <div style={{ marginTop: "-0.5em" }}>
                    <p>
                      <span className="profileDetailText">HR:</span>{" "}
                      {vitalSign.heartRate} bpm
                    </p>
                  </div>
                  <div style={{ marginTop: "-0.5em" }}>
                    <p>
                      <span className="profileDetailText">BP:</span>{" "}
                      {vitalSign.bloodPressure} mmHg
                    </p>
                  </div>
                  <div style={{ marginTop: "-0.5em" }}>
                    <p>
                      <span className="profileDetailText">ECG</span>
                    </p>
                  </div>
                  <div style={{ marginTop: "-0.5em" }}>
                    <p>
                      <span className="profileDetailText">QT:</span>{" "}
                      {vitalSign.qtinterval} sec
                    </p>
                  </div>
                  <div style={{ marginTop: "-0.5em" }}>
                    <p>
                      <span className="profileDetailText">PR:</span>{" "}
                      {vitalSign.printerval} sec
                    </p>
                  </div>
                </div>
                <div style={{ flex: "3" }}>
                  <p>
                    <span className="profileDetailText">O2 Level:</span>{" "}
                    {vitalSign.oxygenLevel} %
                  </p>
                  <div style={{ marginTop: "-0.5em" }}>
                    <p>
                      <span className="profileDetailText">BodyTemp:</span>{" "}
                      {vitalSign.bodyTemprature} F
                    </p>
                  </div>
                  <div style={{ marginTop: "-0.5em" }}>
                    <p>
                      <span className="profileDetailText">Respiration:</span>{" "}
                      {vitalSign.respirationRate} BRPM
                    </p>
                  </div>
                  <div style={{ marginTop: "-0.5em" }}>
                    <p>
                      <span className="profileDetailText">BMI:</span>{" "}
                      {vitalSign.bodyMassIndex}
                    </p>
                  </div>
                  <div style={{ marginTop: "-0.5em" }}>
                    <p style={{ color: "#ffffff" }}>
                      <span className="profileDetailText">ECG</span>
                    </p>
                  </div>
                  <div style={{ marginTop: "-0.5em" }}>
                    <p>
                      <span className="profileDetailText">ST:</span>{" "}
                      {vitalSign.stinterval} sec
                    </p>
                  </div>
                  <div style={{ marginTop: "-0.5em" }}>
                    <p>
                      <span className="profileDetailText">QRS:</span>{" "}
                      {vitalSign.qrsinterval} sec
                    </p>
                  </div>
                </div>
                <div className="linkimage">
                  <img src="detail-icon.png" className="detail-icon"></img>
                  <img src="share_icon.png" className="share-icon"></img>

                  <img
                    src="share_icon.png"
                    className="share-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator
                        .share({
                          title: "Vital sings:-",
                          text: gettheText(vitalSign)
                          // url: ""
                        })
                        .then(() => console.log("shared"))
                        .catch((error) =>
                          console.log(
                            "Oh noh! You couldn't share it! :'(\n",
                            error
                          )
                        );
                    }}
                  ></img>
                </div>
              </div>
            </div>
          ))}


        {isFilterON && (
          <div className="FilterDropdown-header">
            <p className="history-filter-grid-item-head">List</p>
            <p className="history-filter-grid-item-head">

            <div className={containerClassName} onClick={handleToggle}>
          <div className={buttonClassName}></div>
        </div>
            </p>
            <p className="history-filter-grid-item-head">Chart</p>

          </div>
        )}

<hr className="separator-line" />

        {isFilterON && isOn && (

          <LineChart
            chartData={chartData} />
        )}




        {isFilterON && !isOn && (
          <div className="FilterDropdown-header">
            <p className="history-filter-grid-item-head">Date</p>
            <p className="history-filter-grid-item-head">Time</p>
            {selectedValue === "ECG QRS" ||
              selectedValue === "ECG PR" ||
              selectedValue === "ECG QT" ||
              selectedValue === "ECG ST" ? (
              <p className="history-filter-grid-item-head">{selectedValue}</p>
            ) : (
              <p className="history-filter-grid-item-head">{selectedValue}</p>
            )}
          </div>
        )}

        {isFilterON && !isOn &&
          vitalSigns.map((vitalSign, index) => (
            <div key={index} className="history-filter-rectangle">
              <div className="history-filter-grid-container">
                <div className="history-filter-grid-item">
                  <p className="historyFilterTextStyle">{vitalSign.onlyDate}</p>
                </div>
                <div className="history-filter-grid-item">
                  <p className="historyFilterTextStyle">{vitalSign.onlyTime}</p>
                </div>
                <div className="history-filter-grid-item">
                  <p className="historyFilterTextStyle">
                    {vitalSign[selectedKey]} {selectedKeyUnits}
                  </p>
                </div>
              </div>
            </div>
          ))
        }

        {/* {vitalSigns.length === 0 && (
          <div style={{ textAlign: "center", marginTop: "50%" }}>
            <h3>No record found</h3>{" "}
          </div>
        )} */}

        {!isLoading && hasData && (
          <div>
            {vitalSigns.map((sign, index) => (
              <div key={index}> </div>
            ))}
          </div>
        )}



        {/* <div className="white-container" style={{ marginBottom: '10px', width: "96%", background: "#e5e5e6;", position: "relative" }}> */}

        <Modal
          isOpen={modalIsOpen}
          // onRequestClose={closeDialog}
          contentLabel="Take Vital Signs"
          className="historyPopUpModal"
          overlayClassName="historyOverlay"
        >
          <div className="historyModel">
            <div id="header" className="vitalDetailsBorder">
              <div>
                <h2 className="vitalDetailsModal">Vital Details</h2>
              </div>
              <button
                // className="close-button"
                onClick={() => {
                  setModalIsOpen(false);
                }}
                className="historyModelX"
              >
                x
              </button>
            </div>

            <header1 className="historyDateTime">
              <div className="HistoryRecord-title">
                <div style={{ marginLeft: "-4.5em" }}>
                  <span className="profileDetailText">Date:</span> {date}
                </div>
                <div style={{ marginLeft: "5.3em" }}>
                  <span className="profileDetailText">Time:</span> {time}
                </div>
              </div>
            </header1>

            <div style={ECGContainer}className="ECGContainer">

              <div class="Historygrid-container">

                <div class="Historygrid-item">
                  <div className="HistoryImage-container">
                    <img
                      src="blood-pressure.png"
                      alt="Blood Pressure"
                      className="HistorybloodPressureImage"
                    />
                  </div>
                  <div className="HistoryHeader-container">
                    <h4 className="HistoryHeader-title">Blood Pressure</h4>
                  </div>
                  <div className="HistoryDetails-container">
                    {Blood1 !== 0 ? (
                      <>
                        <div className="HistoryValue">{Blood1} mmHg <div className="ibuttonHistory">
                    <button
                      className="instructionButtonHistory"
                      onClick={() => {
                        setCurrentInfo(Info[0]);
                        setCurrentInfo1(Info1[0]);
                        setCurrentInfo2(Info2[0]);
                        setCurrentInfoName(InfoName[0]);
                        setIsPopupOpen(true);
                      }}
                    >
                      ?
                    </button>
                  </div></div>

                      </>
                    ) : (
                      <div className="HistoryValue"> 0
                      <div className="ibuttonHistory">
                    <button
                      className="instructionButtonHistory"
                      onClick={() => {
                        setCurrentInfo(Info[0]);
                        setCurrentInfo1(Info1[0]);
                        setCurrentInfo2(Info2[0]);
                        setCurrentInfoName(InfoName[0]);
                        setIsPopupOpen(true);
                      }}
                    >
                      ?
                    </button>
                  </div>
                  </div>

                    )}
                  </div>
                  <div className="HistoryTilte-contaniner">
                    <div className="HistoryTilte">Normal Range</div>
                  </div>
                  <div className="HistoryRange-container">
                    <div className="HistoryRange">120/80 - 140/90 mmHg</div>
                  </div>
                </div>
                <div class="Historygrid-item">
                  <div className="HistoryImage-container">
                    <img
                      src="oxygen.png"
                      alt="Oxygen Saturation"
                      className="HistoryImage1"
                    />
                  </div>
                  <div className="HistoryHeader-container">
                    <h4 className="HistoryHeader-titleoxy">Oxygen Saturation</h4>
                  </div>
                  <div className="HistoryDetails-container">
                    {oxygen !== 0 ? (
                      <>
                        <div className="HistoryValue">{oxygen} % <div className="ibuttonHistory">
                      <button
                        className="instructionButtonHistory"
                        onClick={() => {
                          setCurrentInfo(Info[1]);
                          setCurrentInfo1(Info1[1]);
                          setCurrentInfo2(Info2[1]);
                          setCurrentInfoName(InfoName[1]);
                          setIsPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div>
                    </div>
                      </>
                    ) : (
                      <div className="HistoryValue"> 0 <div className="ibuttonHistory">
                      <button
                        className="instructionButtonHistory"
                        onClick={() => {
                          setCurrentInfo(Info[1]);
                          setCurrentInfo1(Info1[1]);
                          setCurrentInfo2(Info2[1]);
                          setCurrentInfoName(InfoName[1]);
                          setIsPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div>
                    </div>
                    )}
                  </div>

                  <div className="HistoryTilte-contaniner">
                    <div className="HistoryTilte">Normal Range</div>
                  </div>
                  <div className="HistoryRange-container">
                    <div className="HistoryRange">95 - 100 %</div>
                  </div>
                </div>
                <div class="Historygrid-item">
                  <div className="HistoryImage-container">
                    <img
                      src="heart.png"
                      alt="Heart Rate"
                      className="HistoryImage"
                    />
                  </div>
                  <div className="HistoryHeader-container1 ">
                    <h4 className="HistoryHeader-title1">Heart Rate</h4>
                  </div>
                  <div className="HistoryDetails-container">
                    {HR !== 0 ? (
                      <>
                        <div className="HistoryValue">{HR} bpm <div className="ibuttonHistory">
                      <button
                        className="instructionButtonHistory"
                        onClick={() => {
                          setCurrentInfo(Info[2]);
                          setCurrentInfo1(Info1[2]);
                          setCurrentInfo2(Info2[2]);
                          setCurrentInfoName(InfoName[2]);
                          setIsPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div>
                    </div>
                      </>
                    ) : (
                      <div className="HistoryValue">0
                      <div className="ibuttonHistory">
                      <button
                        className="instructionButtonHistory"
                        onClick={() => {
                          setCurrentInfo(Info[2]);
                          setCurrentInfo1(Info1[2]);
                          setCurrentInfo2(Info2[2]);
                          setCurrentInfoName(InfoName[2]);
                          setIsPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div>
                    </div>
                    )}
                  </div>
                  <div className="HistoryTilte-contaniner">
                    <div className="HistoryTilte">Normal Range</div>
                  </div>
                  <div className="HistoryRange-container">
                    <div className="HistoryRange">60 - 90 bpm</div>
                  </div>
                </div>
                <div class="Historygrid-item">
                  <div className="HistoryImage-container">
                    <img
                      src="Thermometer.png"
                      alt="Body Temperature"
                      className="HistoryImage"
                    />
                  </div>
                  <div className="HistoryHeader-container1 ">
                    <h4 className="HistoryHeader-titleBT">Body Temperature</h4>
                  </div>
                  <div className="HistoryDetails-container">
                    {Temperature !== 0 ? (
                      <>
                        <div className="HistoryValue">{Temperature} F
                        <div className="ibuttonHistory">
                      <button
                        className="instructionButtonHistory"
                        onClick={() => {
                          setCurrentInfo(Info[3]);
                          setCurrentInfo1(Info1[3]);
                          setCurrentInfo2(Info2[3]);
                          setCurrentInfoName(InfoName[3]);
                          setIsECGPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div></div>
                      </>
                    ) : (
                      <div className="HistoryValue">0
                      <div className="ibuttonHistory">
                      <button
                        className="instructionButtonHistory"
                        onClick={() => {
                          setCurrentInfo(Info[3]);
                          setCurrentInfo1(Info1[3]);
                          setCurrentInfo2(Info2[3]);
                          setCurrentInfoName(InfoName[3]);
                          setIsECGPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div></div>
                    )}
                  </div>
                  <div className="HistoryTilte-contaniner">
                    <div className="HistoryTilte">Normal Range</div>
                  </div>
                  <div className="HistoryRange-container">
                    <div className="HistoryRange">96 - 98.4 F</div>
                  </div>
                </div>

                <div className="Historygrid-item">
                  <div className="HistoryImage-container">
                    <img
                      src="lungs.png"
                      alt="Respiration Rate"
                      className="HistoryImage"
                    />
                  </div>
                  <div className="HistoryHeader-container">
                    <h4 className="HistoryHeader-title">Respiration Rate</h4>
                  </div>
                  <div className="HistoryDetails-container">
                    {Respiration !== 0 ? (
                      <>
                        <div className="HistoryValue">{Respiration} BRPM <div className="ibuttonHistory">
                      <button
                        className="instructionButtonHistory"
                        onClick={() => {
                          setCurrentInfo(Info[4]);
                          setCurrentInfo1(Info1[4]);
                          setCurrentInfo2(Info2[4]);
                          setCurrentInfoName(InfoName[4]);
                          setIsECGPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div></div>
                        {/* <div className="result">{setRespriationValue}</div> */}
                      </>
                    ) : (
                      <div className="HistoryValue"> 0
                      <div className="ibuttonHistory">
                      <button
                        className="instructionButtonHistory"
                        onClick={() => {
                          setCurrentInfo(Info[4]);
                          setCurrentInfo1(Info1[4]);
                          setCurrentInfo2(Info2[4]);
                          setCurrentInfoName(InfoName[4]);
                          setIsECGPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div></div>
                    )}
                  </div>
                  <div className="HistoryTilte-contaniner">
                    <div className="HistoryTilte">Normal Range</div>
                  </div>
                  <div className="HistoryRange-container">
                    <div className="HistoryRange">12 - 20 BRPM</div>
                  </div>
                </div>
                <div className="Historygrid-item">
                  <div className="HistoryImage-container">
                    <img
                      src="bmi.png"
                      alt="Body Mass Index"
                      className="HistoryImage"
                    />
                  </div>
                  <div className="HistoryHeader-container">
                    <h4 className="HistoryHeader-title">Body Mass Index</h4>
                  </div>
                  <div className="HistoryDetails-container">
                    {bmi !== 0 ? (
                      <>
                        {/* <div className="value">{bmi.toFixed(2)}</div> */}
                        <div className="HistoryValue">{bmi}  <div className="ibuttonHistory">
                      <button
                        className="instructionButtonHistory"
                        onClick={() => {
                          setCurrentInfo(Info[5]);
                          setCurrentInfo1(Info1[5]);
                          setCurrentInfo2(Info2[5]);
                          setCurrentInfoName(InfoName[5]);
                          setIsECGPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div>
                    </div>
                        {/* <div className="result">{setBmiValue}</div> */}
                      </>
                    ) : (
                      <div className="HistorValue"> 0  <div className="ibuttonHistory">
                      <button
                        className="instructionButtonHistory"
                        onClick={() => {
                          setCurrentInfo(Info[5]);
                          setCurrentInfo1(Info1[5]);
                          setCurrentInfo2(Info2[5]);
                          setCurrentInfoName(InfoName[5]);
                          setIsECGPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div>
                    </div>
                    )}
                  </div>
                  <div className="HistoryTilte-contaniner">
                    <div className="HistoryTilte">Normal Range</div>
                  </div>
                  <div className="HistoryRange-container">
                    <div className="HistoryRange">18.5 - 25</div>
                  </div>
                </div>
              </div>

<div className="HistoryECGContainer">
<Plot data={[trace]} layout={layout} />
</div>



              <div style={{ marginTop: "2.5em" }}>
                <center>
                  <h3 className="HistoryECGIntervelText1">ECG Intervals</h3>
                </center>

                <div className="HistoryECGgrid-container">
                  <div className="Historygrid-item ">
                    <div className="HistoryHeader-container">
                      <h3 className="HistoryHeader-title2">QT Interval</h3>
                    </div>
                    <div className="HistoryDetails-container">
                      <div className="HistoryValue"> {qt} sec<div className="ibuttonHistory">
                      <button
                        className="instructionButtonHistory"
                        onClick={() => {
                          setCurrentInfo(Info[6]);
                          setCurrentInfo1(Info1[6]);
                          setCurrentInfo2(Info2[6]);
                          setCurrentInfoName(InfoName[6]);
                          setIsECGPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div></div>
                    </div>
                    <div className="HistoryTilte-contaniner">
                      <div className="HistoryTilteT">Normal Range</div>
                    </div>
                    <div className="HistoryRange-container1">
                      <div className="HistoryRange5">0.35 - 0.46 Sec</div>
                    </div>
                  </div>

                  <div className="Historygrid-item ">
                    <div className="HistoryHeader-container">
                      <h3 className="HistoryHeader-title2">ST Segment</h3>
                    </div>
                    <div className="HistoryDetails-container">
                      <div className="HistoryValue"> {ST} sec <div className="ibuttonHistory">
                      <button
                        className="instructionButtonHistory"
                        onClick={() => {
                          setCurrentInfo(Info[7]);
                          setCurrentInfo1(Info1[7]);
                          setCurrentInfo2(Info2[7]);
                          setCurrentInfoName(InfoName[7]);
                          setIsECGPopupOpen1(true);
                        }}
                      >
                        ?
                      </button>
                    </div>
                    </div>
                    </div>
                    <div className="HistoryTilte-contaniner">
                      <div className="HistoryTilteT">Normal Range</div>
                    </div>
                    <div className="HistoryRange-container1">
                      <div className="HistoryRange5">0.08 - 0.12 Sec</div>
                    </div>

                  </div>

                  <div className="Historygrid-item ">
                    <div className="HistoryHeader-container">
                      <h3 className="HistoryHeader-title2">PR Interval</h3>
                    </div>
                    <div className="HistoryDetails-container">
                      <div className="HistoryValue"> {Pr} sec <div className="ibuttonHistory">
                      <button
                        className="instructionButtonHistory"
                        onClick={() => {
                          setCurrentInfo(Info[8]);
                          setCurrentInfo1(Info1[8]);
                          setCurrentInfo2(Info2[8]);
                          setCurrentInfoName(InfoName[8]);
                          setIsECGPopupOpen1(true);
                        }}
                      >
                        ?
                      </button>
                    </div>
                    </div>
                    </div>
                    <div className="HistoryTilte-contaniner">
                      <div className="HistoryTilteT">Normal Range</div>
                    </div>
                    <div className="HistoryRange-container1">
                      <div className="HistoryRange6">0.12 - 0.20 Sec</div>
                    </div>

                  </div>

                  <div className="Historygrid-item ">
                    <div className="HistoryHeader-container">
                      <h3 className="HistoryHeader-title2">QRS Interval</h3>
                    </div>
                    <div className="HistoryDetails-container">
                      <div className="HistoryValue"> {Qrs} sec
                      <div className="ibuttonHistory">
                      <button
                        className="instructionButtonHistory"
                        onClick={() => {
                          setCurrentInfo(Info[9]);
                          setCurrentInfo1(Info1[9]);
                          setCurrentInfo2(Info2[9]);
                          setCurrentInfoName(InfoName[9]);
                          setIsECGPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div></div>
                    </div>
                    <div className="HistoryTilte-contaniner">
                      <div className="HistoryTilteT">Normal Range</div>
                    </div>
                    <div className="HistoryRange-container1">
                      <div className="HistoryRange6">0.06 - 0.10 Sec</div>
                    </div>

                  </div>
                </div>
              </div>

              <div
                style={{
                  position: "absolute",
                  top: "23em",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              ></div>
            </div>
          </div>
          {isPopupOpen && (
          <div> <div className="homePageOverlay"></div></div>
        )}
          {isECGPopupOpen && (
          <div> <div className="homePageOverlay"></div></div>
        )}
          {isECGPopupOpen1 && (
          <div> <div className="homePageOverlay"></div></div>
        )}

          {isPopupOpen && (
        <div>
          {/* <div className="homePageOverlay"></div> */}
          <div className="Historypopup">

            <div className="popupheader">
              <b>{currentInfoName}</b>
            </div>
            <div className="popup-content">
              <div className="instructionContent">
                {currentInfo}
                <br />
                <br />
                Normal Range: <b>{currentInfo1} </b>
                <br />
                <br />
                Your Reading: <b>{currentInfo2}</b>
              </div>
              <button className="instructionCloseButton" onClick={closePopup}>
                Ok
              </button>
            </div>
          </div></div>


      )}

{isECGPopupOpen && (
        <div>
          {/* <div className="homePageOverlay"></div> */}
          <div className="HistoryECGIntervalPopup">

            <div className="popupheader">
              <b>{currentInfoName}</b>
            </div>
            <div className="popup-content">
              <div className="instructionContent">
                {currentInfo}
                <br />
                <br />
                Normal Range: <b>{currentInfo1} </b>
                <br />
                <br />
                Your Reading: <b>{currentInfo2}</b>
              </div>
              <button className="instructionCloseButton" onClick={closePopup}>
                Ok
              </button>
            </div>
          </div></div>


      )}

{isECGPopupOpen1 && (
        <div>
          {/* <div className="homePageOverlay"></div> */}
          <div className="HistoryECGIntervalPopup1">

            <div className="popupheader">
              <b>{currentInfoName}</b>
            </div>
            <div className="popup-content">
              <div className="instructionContent">
                {currentInfo}
                <br />
                <br />
                Normal Range: <b>{currentInfo1} </b>
                <br />
                <br />
                Your Reading: <b>{currentInfo2}</b>
              </div>
              <button className="instructionCloseButton" onClick={closePopup}>
                Ok
              </button>
            </div>
          </div></div>


      )}
        </Modal>
      </div>

      <footer
        className="historyFooter"
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "navy",
          marginTop: "100vh",
          width: "100%",
          backgroundImage: `url(Indian-Girls.jpg)`,
        }}
      >
        {/* <div> */}
        <div
          style={{
            color: "orange",
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          YourVitals, Inc.{" "}
        </div>
        <div style={{ color: "#ffffff" }}>© 2023, All Rights Reserved.</div>

        <div style={{ alignItems: "center" }}>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open("https://yourvitals.ai/terms_of_use.html", "_blank");
            }}
          >
            Terms Of Use
          </button>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(
                "https://yourvitals.ai/privacy_policy.html",
                "_blank"
              );
            }}
          >
            Privacy Policy
          </button>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open("https://yourvitals.ai/#", "_blank");
            }}
          >
            FAQ
          </button>
        </div>
      </footer>
      {isLoading && <LoadingSpinner />}

    </div>
  );
};

export default History;
