// import "./App.css";
import "./homePage.css";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { getCountryCallingCode } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import { BASE_API_URL, BASE_API_URL1 } from "./content";
// import * as signalR from '@microsoft/signalr';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { preventReload } from "./content.js";
import Plot from "react-plotly.js";

Modal.setAppElement("#root");

function HomePage() {
  const [showPassword, setShowPassword] = useState(false);
  const [mustSave, setMustSave] = useState(false);

  const [guid, setguid] = useState(localStorage.getItem("guid"));
  const [time, setTime] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Added loading state

  const [isSaveYes, setIsSaveYes] = useState(false);
  const [isSaveNo, setIsSaveNo] = useState(false);
  const [isLogout, setIsLogout] = useState(false);

  const proxyURL = "https://cors-anywhere.herokuapp.com/"; //${proxyURL}
  const updateUrl = `${BASE_API_URL1}Patient/PUT`;
  const loginUrl = `${BASE_API_URL1}Login`;
  const getHistoryUrl = `${BASE_API_URL1}PatientVitalSigns/GetDetails_V2`;
  const saveVitalsUrl = `${BASE_API_URL1}PatientVitalSigns/Post_V1`;
  const changePwdUrl = `${BASE_API_URL}ForgetPWD/UpdateChangePassword`;

  const [userCameFromCameraVitals, setuserCameFromCameraVitals] = useState(
    localStorage.getItem("0")
  );
  const [HR, setHR] = useState(localStorage.getItem("HR"));
  const [Blood1, setBloodp] = useState(localStorage.getItem("bloodp"));
  const [Temperature, setTemp] = useState(localStorage.getItem("temp"));
  const [oxygen, setOxyzen] = useState(localStorage.getItem("oxyzen"));
  const [Respiration, setResp] = useState(localStorage.getItem("resp"));
  const [qt, setQt] = useState(localStorage.getItem("qt"));
  const [Qrs, setQrs] = useState(localStorage.getItem("qrs"));
  const [ST, setSt] = useState(localStorage.getItem("st"));
  const [Pr, setPr] = useState(localStorage.getItem("pr"));
  const [bmi, setBMI] = useState(localStorage.getItem("Bmi1"));
  const [scrollX, setScrollX] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [UpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [AlertModel, setAlertModel] = useState(false);
  const [AlertModel0, setAlertModel0] = useState(false);

  const [dialogueVisible, setDialogueVisible] = useState(false);

  const [gender1, setGender1] = useState(localStorage.getItem("gender"));
  const [age1, setAge1] = useState(localStorage.getItem("about"));
  const [height1, setHeight1] = useState(localStorage.getItem("height"));
  const [weight1, setWeight1] = useState(localStorage.getItem("weight"));

  const [userPhoneNumber, setuserPhoneNumberr] = useState(
    localStorage.getItem("userPhoneNumber")
  );
  const [gender, setGender] = useState(localStorage.getItem("gender"));
  const [age, setAge] = useState(localStorage.getItem("about"));
  const [height, setHeight] = useState(localStorage.getItem("height"));
  const [weight, setWeight] = useState(localStorage.getItem("weight"));
  const [password, setpassword] = useState(localStorage.getItem("password"));
  const [userName, setuserName] = useState(localStorage.getItem("userName"));
  const [phoneNumber, setphoneNumber] = useState(
    localStorage.getItem("phoneNumber")
  );
  const [firstName, setfirstName] = useState(localStorage.getItem("firstName"));
  const [lastName, setlastName] = useState(localStorage.getItem("lastName"));
  const [bloodGroup, setbloodGroup] = useState(
    localStorage.getItem("bloodGroup")
  );
  const [address, setaddress] = useState(localStorage.getItem("address"));
  const [email, setemail] = useState(localStorage.getItem("email"));
  const [dob, setdob] = useState(localStorage.getItem("dob"));
  const [medicalPredisposition, setmedicalPredisposition] = useState(
    localStorage.getItem("medicalPredisposition")
  );
  const [about, setabout] = useState(localStorage.getItem("about"));
  const [city, setcity] = useState(localStorage.getItem("city"));
  const [state, setstate] = useState(localStorage.getItem("state"));
  const [pinCode, setpinCode] = useState(localStorage.getItem("pinCode"));
  const [regType, setregType] = useState(localStorage.getItem("regType"));
  const [specialistFees, setspecialistFees] = useState(
    localStorage.getItem("specialistFees")
  );
  const [specializationName, setspecializationName] = useState(
    localStorage.getItem("specializationName")
  );
  const [profileIMG, setprofileIMG] = useState(
    localStorage.getItem("profileIMG")
  );
  const [weighttype, setweighttype] = useState(
    localStorage.getItem("weighttype")
  );
  const [heighttype, setheighttype] = useState(
    localStorage.getItem("heighttype")
  );
  const [feet, setfeet] = useState(localStorage.getItem("feet"));
  const [inches, setinches] = useState(localStorage.getItem("inches"));
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [bmiDescription, setBMIDescription] = useState("");
  const [from, setFrom] = useState("login");
  // Add these state variables at the top of your component
  const [zoomLevel, setZoomLevel] = useState(1);
  const zoomFactor = 0.1; // You can adjust this factor as needed

  const [showContainer, setShowContainer] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const rotation = isRotated ? 90 : 0;

  const [okButtonClicked, setOkButtonClicked] = useState(false);
  const [shouldContinueInterval, setShouldContinueInterval] = useState(true);
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [dialCodes, setDialCode] = useState("");
  const [countryCode, setCountryCode] = useState(localStorage.getItem("Ccode"));
  // const [unit, setUnit] = useState("cms"); // 'cms', 'ftinches'
  // const [weightUnit, setWeightUnit] = useState("kg");

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isECGPopupOpen, setIsECGPopupOpen] = useState(false);
  const [isECGPopupOpen1, setIsECGPopupOpen1] = useState(false);
  const [currentInfo, setCurrentInfo] = useState("");
  const [currentInfoName, setCurrentInfoName] = useState("");
  const [currentInfo1, setCurrentInfo1] = useState("");
  const [currentInfo2, setCurrentInfo2] = useState("");

  const [DateH, setDateH] = useState("");
  const [TimeH, setTimeH] = useState("");

  var updateFlag = true;
  var notMobileFlag = true;

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setIsECGPopupOpen(false);
    setIsECGPopupOpen1(false);
  };

  window.onload = () => {
    // setShowPopup(true);
    Navigate("/Home-Page");
  };

  const toggleUnit = (e) => {
    if (heighttype === "CM") {
      if (isNaN(height)) {
        setHeight(0);
        setfeet(0);
        setheighttype("FT/IN");
        setinches(0);
      } else {
        const totalInches = Math.round(height / 2.54);
        setfeet(Math.floor(totalInches / 12));
        setinches(totalInches % 12);
        setheighttype("FT/IN");
      }
    } else {
      if (isNaN(feet) && isNaN(inches)) {
        setHeight(0);
        setfeet(0);
        setheighttype("CM");
        setinches(0);
      } else {
        const totalInches = parseInt(feet) * 12 + parseInt(inches);
        setHeight(Math.round(totalInches * 2.54)); // Convert inches to cms
        setheighttype("CM");
      }
    }
  };
  const toggleWeightUnit = (e) => {
    if (weighttype === "KG") {
      // Checked state corresponds to lbs
      setWeight(Math.round(weight * 2.20462)); // Convert kg to lbs
      setweighttype("LB");
    } else {
      // Unchecked state corresponds to kg
      setWeight(Math.round(weight / 2.20462)); // Convert lbs to kg
      setweighttype("KG");
    }
  };

  const CloseModel = () => {
    setAlertModel0(false);
    setAlertModel(false);
  };

  const toggleECGContainer = () => {
    setShowContainer(!showContainer);
    setIsRotated(!isRotated);
  };

  const imgStyle = {
    position: "absolute",
    marginTop: "0.2em",
    right: "13px",
    height: "0.7em",
    width: "0.7em",
    transform: `rotate(${rotation}deg)`,
  };

  const zoomECG = (zoomIn) => {
    if (zoomIn) {
      if (zoomLevel < 1.7) {
        setZoomLevel((prevZoom) => Math.min(prevZoom + zoomFactor, 5));
      }
    } else {
      if (zoomLevel > 1) {
        setZoomLevel((prevZoom) => Math.max(prevZoom - zoomFactor, 1));
      }
    }
  };

  const navigate = useNavigate();

  const takeVitalSigns = () => {
    setValidationError(false);
    setModalIsOpen(true);
  };

  const profileButton = () => {
    setValidationError(false);
    setModalIsOpen1(true);
  };

  const GoToHistory = () => {
    // const SaveGet = localStorage.getItem('SaveGet');
    if (showSaveButton) {
      setMustSave(true);
    } else {
      navigate("/history");
    }
  };

  const GoHistory = () => {
    setMustSave(false);
    navigate("/history");
  };

  const closeDialog = () => {
    setModalIsOpen(true);
  };

  const closeDialog1 = () => {
    setModalIsOpen1(true);
  };

  const closeModal1 = () => {
    setIsModalOpen1(false);
    setIsModalOpen(false);
    closeModal();
    callVitalsHistoryOnLoad();
    login();
  };

  const closeModal12 = () => {
    setIsModalOpen1(false);
    setIsModalOpen(false);
    closeModal();
  };

  const handleStartClick = () => {
    if (gender && age > 0 && weight > 0) {
      if (
        (heighttype === "CM" && height > 0) ||
        (heighttype === "FT/IN" && feet > 0 && inches >= 0)
      ) {
        const userAgent = navigator.userAgent;
        setValidationError(false);
        // setModalIsOpen(false);
        // navigate('/takevitals');
        if (userAgent.match(/Android/i) || userAgent.match(/iPhone/i)) {
          navigate("/takevitals");
          validateForm();
        } else {
          // openModal();
          notMobileFlag = false;
          updateProfileData();
        }
      }
      setValidationError(false);
    } else {
      setValidationError(true);
    }
  };

  const validateForm = () => {
    if (gender && age > 0 && weight > 0) {
      if (
        (heighttype === "CM" && height > 0) ||
        (heighttype === "FT/IN" && feet > 0 && inches >= 0)
      ) {
        setValidationError(false);
        // setModalIsOpen1(false);
        // navigate("/takevitals");
        calculateBMI();
        // updateProfileData();
        updatePwdAndProfile();
        // setUpdateModalOpen(true);
      } else {
        setValidationError(true);
      }
    } else {
      setValidationError(true);
    }
  };

  const updatePwdAndProfile = () => {
    changePwd();
  };
  // const UpdatevalidateForm = () => {
  //   if (!gender || age <= 0 || height <= 0 || weight <= 0) {
  //     setValidationError(true);
  //   } else {
  //     setValidationError(false);

  //     // navigate("/takevitals");
  //     updateProfileData1();
  //     calculateBMI();
  //   }
  // };

  const calculateBMI = () => {
    var weightinKG = weight;

    if (heighttype === "FT/IN") {
      setHeight(feet * 30.48 + inches * 2.54);
    }
    if (weighttype === "LB") {
      weightinKG = weight * 0.453592;
    }

    if (weight > 0 && height > 0) {
      const heightInMeters = height / 100;
      const bmiValue1 = weightinKG / (heightInMeters * heightInMeters);
      const bmiValue = bmiValue1.toFixed(2);
      localStorage.setItem("Bmi1", bmiValue);
      console.log("bMi..+++--", +bmiValue);
    }
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);

  };

  const handleInputChange = (e) => {
    if (isEditing) {
      setpassword(e.target.value);
    }
  };

  const [hasPerformedInitialSetup, setHasPerformedInitialSetup] =
    useState(false);
  var isSuccess = true;
  isSuccess = localStorage.getItem("isSuccess");

  // Perform initial setup only once when isSuccess becomes true
  if (isSuccess && hasPerformedInitialSetup) {
    setHasPerformedInitialSetup(true);

    localStorage.setItem("isSuccess", false);
  }

  const svgStyle = {
    display: "block",
    margin: "auto",
  };

  const numVerticalLines = 10;
  const HorizontalLineSpacing = ((465 - 50) / (numVerticalLines - 1)) * 1.8;
  const verticalLineSpacing = ((223 - 50) / (numVerticalLines - 1)) * 1.8;

  const heading = {
    backgroundColor: "navy",
    color: "white",
    padding: "2px",
    textAlign: "center",
    fontWeight: "semi-bold",
    fontSize: "25px",
  };

  const verticalLines = [];
  for (let i = 0; i < numVerticalLines; i++) {
    const x = 90 + i * verticalLineSpacing;
    const color = i === 9 ? "black" : "gray"; // Change color to black for the 10th line
    verticalLines.push(
      <line
        key={i}
        x1={x}
        y1={50}
        x2={x}
        y2={300}
        stroke={color}
        strokeWidth="1"
      />
    );
  }
  const RR = parseFloat(localStorage.getItem("qt"));
  // ECG data (sample data, replace with your actual data)
  const ecgData = [
    { x: 0, y: RR + 0 },
    { x: 0.1, y: RR + 0.05 },
    { x: 0.12, y: RR + 0.1 },
    { x: 0.2, y: RR + 0.05 },
    { x: 0.21, y: RR + 0 },
    { x: 0.26, y: RR },
    { x: 0.31, y: RR - 0.17 },
    { x: 0.35, y: RR + 0 },
    { x: 0.4, y: RR + 0 },
    { x: 0.45, y: RR + 0.05 },
    { x: 0.5, y: RR + 0.06 },
    { x: 0.53, y: RR + 0.07 },
    { x: 0.58, y: RR + 0.06 },
    { x: 0.6, y: RR + 0.05 },
    { x: 0.65, y: RR + 0 },
    { x: 0.68, y: RR + 0 },
    { x: 0.7, y: RR + 0 },
    { x: 0.75, y: RR + 0 },
    { x: 0.78, y: RR + 0.01 },
    { x: 0.8, y: RR + 0.02 },
    { x: 0.82, y: RR + 0.03 },
    { x: 0.85, y: RR + 0.02 },
    { x: 0.86, y: RR + 0.01 },
    { x: 0.88, y: RR + 0 },
    { x: 0.9, y: RR },
    { x: 0.9, y: RR },
    { x: 0.92, y: RR + 0 },
    { x: 0.95, y: RR + 0 },
    { x: 1, y: RR + 0.1 },
    { x: 1.1, y: RR + 0.05 },
    { x: 1.15, y: RR + 0.1 },
    { x: 1.16, y: RR + 0.05 },
    { x: 1.17, y: RR + 0 },
    { x: 1.25, y: RR + 1 },
    { x: 1.3, y: RR - 0.17 },
    { x: 1.4, y: RR + 0 },
    { x: 1.55, y: RR + 0 },
    { x: 1.65, y: RR + 0.05 },
    { x: 1.71, y: RR + 0.06 },
    { x: 1.75, y: RR + 0.07 },
    { x: 1.78, y: RR + 0.06 },
    { x: 1.79, y: RR + 0.05 },
    { x: 1.8, y: RR + 0.0 },
    { x: 2, y: RR + 0.1 },
    { x: 2.1, y: RR + 0.05 },
    { x: 2.12, y: RR + 0.1 },
    { x: 2.2, y: RR + 0.05 },
    { x: 2.21, y: RR + 0 },
    { x: 2.26, y: RR },
    { x: 2.31, y: RR - 0.17 },
    { x: 2.35, y: RR + 0 },
    { x: 2.4, y: RR + 0 },
    { x: 2.45, y: RR + 0.05 },
    { x: 2.5, y: RR + 0.06 },
    { x: 2.53, y: RR + 0.07 },
    { x: 2.58, y: RR + 0.06 },
    { x: 2.6, y: RR + 0.05 },
    { x: 2.65, y: RR + 0 },
    { x: 2.68, y: RR + 0 },
    { x: 2.7, y: RR + 0 },
    { x: 2.75, y: RR + 0 },
    { x: 2.78, y: RR + 0.01 },
    { x: 2.8, y: RR + 0.02 },
    { x: 2.82, y: RR + 0.03 },
    { x: 2.85, y: RR + 0.02 },
    { x: 2.86, y: RR + 0.01 },
    { x: 2.88, y: RR + 0 },
    { x: 2.9, y: RR },
    { x: 2.9, y: RR },
    { x: 2.92, y: RR + 0 },
    { x: 2.95, y: RR + 0 },
    { x: 3, y: RR + 0.1 },
    { x: 3.1, y: RR + 0.05 },
    { x: 3.15, y: RR + 0.1 },
    { x: 3.16, y: RR + 0.05 },
    { x: 3.17, y: RR + 0 },
    { x: 3.25, y: RR + 1 },
    { x: 3.3, y: RR - 0.17 },
    { x: 3.4, y: RR + 0 },
    { x: 3.55, y: RR + 0 },
    { x: 3.65, y: RR + 0.05 },
    { x: 3.71, y: RR + 0.06 },
    { x: 3.75, y: RR + 0.07 },
    { x: 3.78, y: RR + 0.06 },
    { x: 3.79, y: RR + 0.05 },
    { x: 3.8, y: RR + 0.0 },
    { x: 4, y: RR + 0.1 },
  ];

  // Calculate the scaling factors based on chart dimensions and data range
  const xScale = (361 - 50) / (ecgData.length - 1);
  const yScale =
    (148 - 50) /
    (Math.max(...ecgData.map((point) => point.y)) -
      Math.min(...ecgData.map((point) => point.y)));

  // Convert ECG data to SVG path
  const ecgPath = `M${ecgData
    .map(
      (point, index) =>
        `${90 + index * xScale},${
          230 -
          (point.y - Math.min(...ecgData.map((point) => point.y))) * yScale
        }`
    )
    .join(" L")}`;

  const headingStyles = {
    width: "20px",
    backgroundColor: "navy",
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    display: "flex",
  };

  const handleScrollX = (scroll) => {
    const container = document.getElementById("ecg-container");
    if (container) {
      const maxScrollX = container.scrollWidth - container.clientWidth;
      setScrollX(Math.min(maxScrollX, Math.max(0, scroll)));
    }
  };

  const handleScrollY = (scroll) => {
    const container = document.getElementById("ecg-container");
    if (container) {
      const maxScrollY = container.scrollHeight - container.clientHeight;
      setScrollY(Math.min(maxScrollY, Math.max(0, scroll)));
    }
  };

  useEffect(() => {
    preventReload();

    handleScrollX(scrollX);
    handleScrollY(scrollY);
  }, [scrollX, scrollY]);

  const HomepageECGContainer = {
    scrollLeft: scrollX,
    scrollTop: scrollY, // Set scrollTop to scrollY
  };

  const ECGGraphContainer = {
    display: "flex",
    backgroundColor: "#efefef",
    transform: `scale(${zoomLevel})`,
    width: "96%",
    transformOrigin: "center",
    position: "absolute",
    top: "0",
    left: "0",
    transition: "width 0.2s, height 0.2s",
    overflow: "auto",
  };

  const Navigate = useNavigate();
  const backButton = () => {
    Navigate("/");
  };

  // msd
  const okClick = () => {
    setIsSaveYes(false);
    setShowSaveButton(false);
    setRetakeVitalsButton(true);
  };

  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showElectrodiagram, setshowElectrodiagram] = useState(false);
  const [RetakeVitalsButton, setRetakeVitalsButton] = useState(true);

  // useEffect(() => {
  //   const userCameFromCameraVitals = localStorage.getItem('cameFromCameraVitals');

  //   if (userCameFromCameraVitals) {
  //     setShowSaveButton(true);

  //     localStorage.removeItem('cameFromCameraVitals');
  //   }
  // }, []);

  const openModal = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsModalOpen(true);
    }, 1200);
  };

  useEffect(() => {
    const NewUserAlert = localStorage.getItem("WelcomeAlert");
    setshowElectrodiagram(true);

    if (NewUserAlert === "true") {
      setAlertModel(true);
      setAlertModel0(false);
      localStorage.setItem("WelcomeAlert", "false");
    }

    const userCameFromCameraVitals = localStorage.getItem(
      "cameFromCameraVitals"
    );
    setTime(getCurrentTime());

    if (userCameFromCameraVitals) {
      // setuserCameFromCameraVitals(localStorage.getItem('cameFromCameraVitals'));
      setShowSaveButton(true);
      setDateTimeAfterScan();
      setRetakeVitalsButton(false);
      setshowElectrodiagram(true);
      setGraph(true);

      setHR(localStorage.getItem("HR"));
      setResp(localStorage.getItem("resp"));
      setBloodp(localStorage.getItem("bloodp"));
      // setBloodp("ee");
      setTemp(localStorage.getItem("temp"));
      setBMI(localStorage.getItem("Bmi1"));

      setOxyzen(localStorage.getItem("oxyzen"));
      setSt(localStorage.getItem("st"));
      setPr(localStorage.getItem("pr"));
      setQrs(localStorage.getItem("qrs"));
      setQt(localStorage.getItem("qt"));
      localStorage.removeItem("cameFromCameraVitals");
    } else {
      callVitalsHistoryOnLoad();
    }
  }, []);

  useEffect(() => {
    login();

    setguid(localStorage.getItem("guid"));
    setFrom(localStorage.getItem("from"));
    setGender(localStorage.getItem("gender"));
    setHeight(localStorage.getItem("height"));
    setWeight(localStorage.getItem("weight"));

    setuserPhoneNumberr(localStorage.getItem("userPhoneNumber"));
    setpassword(localStorage.getItem("password"));
    setuserName(localStorage.getItem("userName"));
    setphoneNumber(localStorage.getItem("phoneNumber"));
    setfirstName(localStorage.getItem("firstName"));
    setlastName(localStorage.getItem("lastName"));
    setbloodGroup(localStorage.getItem("bloodGroup"));
    setaddress(localStorage.getItem("address"));
    setemail(localStorage.getItem("email"));
    setdob(localStorage.getItem("dob"));
    setmedicalPredisposition(localStorage.getItem("medicalPredisposition"));
    setabout(localStorage.getItem("about"));
    setcity(localStorage.getItem("city"));
    setstate(localStorage.getItem("state"));
    setpinCode(localStorage.getItem("pinCode"));
    setregType(localStorage.getItem("regType"));
    setspecialistFees(localStorage.getItem("specialistFees"));
    setspecializationName(localStorage.getItem("specializationName"));
    setprofileIMG(localStorage.getItem("profileIMG"));
    setweighttype(localStorage.getItem("weighttype"));
    setheighttype(localStorage.getItem("heighttype"));

    handleScrollX(scrollX);
    handleScrollY(scrollY);
  }, [scrollX, scrollY]);

  const DateConverter = () => {
    if (dob === "") {
      return "2000-01-01";
    } else {
      const dateParts = dob.split(" ")[0].split("/");
      const formattedDate = `${dateParts[2]}-${dateParts[0].padStart(
        2,
        "0"
      )}-${dateParts[1].padStart(2, "0")}`;
      return formattedDate;
    }
  };

  var updaterequestBaby = {
    StaffGUID: guid,
    UserName: userPhoneNumber,
    about: age,
    Password: password,
    FirstName: firstName,
    LastName: lastName,
    email: email,
    PhoneNumber: phoneNumber,
    DateofBirth: DateConverter(),
    Gender: gender,
    Address: address,
    City: city,
    State: state,
    Zip: pinCode,
    MedicalPredisposition: medicalPredisposition,
    height: parseInt(height),
    weight: parseInt(weight),
    BloodGroup: bloodGroup,
    heighttype: heighttype,
    RegType: regType,
    weighttype: weighttype,
    Feet: parseInt(feet),
    Inches: parseInt(inches),
    ProfileImageURL: profileIMG,
  };

  var chabgePwdBody = {
    Password: password,
    UserName: userPhoneNumber,
  };

  const changePwd = () => {
    axios
      .post(changePwdUrl, chabgePwdBody, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // Handle the response data here
        console.log("data", response.data);
        var data = response.data.response;
        // var errorjson = JSON.parse();
        if (data == "Success") {
          localStorage.setItem("password", password);

          updateProfileData();
        }
      })
      .catch((error) => {
        // Handle any errors here

        if (error.response.status === 404) {
          changePwd();
        }
        console.error("Error:1111", error);
      })
      .finally(() => {});
  };

  const updateProfileData = () => {
    console.log("requesting", updaterequestBaby);

    axios
      .post(updateUrl, updaterequestBaby, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("data", response.data);
        var data = response.data.response;
        // var errorjson = JSON.parse();
        if (data == "Success") {
          console.log("success=", response.data.response);
          console.log("result", response.data.result);
          // navigate("/takevitals");
          localStorage.setItem("gender", gender);
          localStorage.setItem("about", age);
          localStorage.setItem("height", height);
          localStorage.setItem("weight", weight);
          login();
        } else {
          console.log("error ", response.data.errormessage);
        }
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const closeModal = () => {
    navigate("/Home-Page");
  };

  const modalStyle2 = {
    display: AlertModel ? "block" : "none",
  };

  const openModalStyle = {
    display: UpdateModalOpen ? "block" : "none",
  };

  const modalStyle1 = {
    display: isModalOpen1 ? "block" : "none",
  };

  const modalStyle0 = {
    display: AlertModel0 ? "block" : "none",
  };

  const overlayStyle = {
    display: isModalOpen ? "block" : "none",
  };
  const overlayStyle1 = {
    display: isModalOpen1 ? "block" : "none",
  };

  const saveoverlayStyle = {
    display: isSaveYes ? "block" : "none",
  };

  const logoutoverlayStyle = {
    display: isLogout ? "block" : "none",
  };

  const grayOverlayStyle = {
    display: AlertModel ? "block" : "none",
  };
  const grayOverlayStyle0 = {
    display: AlertModel0 ? "block" : "none",
  };

  const grayUpdateOverlayStyle = {
    display: UpdateModalOpen ? "block" : "none",
  };

  const modalStyle = {
    display: isModalOpen ? "block" : "none",
  };

  const okButtonStyle = {
    backgroundColor: "navy",
    color: "white",
    fontWeight: "bold",
    position: "absolute",
    bottom: "10px",
    left: "35%",
    transform: "translateX(-50%)",
    cursor: "pointer",
    padding: "10px 30px",
    borderRadius: "5px",
  };
  const RefreshButtonStyle = {
    backgroundColor: "navy",
    color: "white",
    fontWeight: "bold",
    position: "absolute",
    bottom: "10px",
    left: "35%",
    transform: "translateX(-50%)",
    cursor: "pointer",
    padding: "10px 20px",
    borderRadius: "5px",
  };
  const updateProfileData1 = () => {
    console.log("requesting", updaterequestBaby);

    axios
      .post(updateUrl, updaterequestBaby, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("data", response.data);
        var data = response.data.response;
        // var errorjson = JSON.parse();
        if (data == "Success") {
          console.log("success=", response.data.response);
          console.log("result", response.data.result);
          setModalIsOpen1(false);

          localStorage.setItem("gender", gender);
          localStorage.setItem("about", age);
          localStorage.setItem("height", height);
          localStorage.setItem("weight", weight);
        } else {
          console.log("error ", response.data.errormessage);
        }
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const getCurrentTime = () => {
    const now = new Date();

    // Get the current year, month, day, hours, and minutes
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    // Create the formatted date and time string
    const formattedTime = `${year}-${month}-${day}T${hours}:${minutes}:00`;

    setTime(formattedTime);
    return formattedTime;
  };

  const setDateTimeAfterScan = () => {
    const now = new Date();

    // Get the current year, month, day, hours, and minutes
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    // Create the formatted date and time string
    // const formattedTime = `${year}-${month}-${day}T${hours}:${minutes}:00`;
    setDateH(day + "/" + month + "/" + year);
    setTimeH(hours + ":" + minutes);
  };

  var requestBody = {
    PatientGUID: guid,
  };

  const callHistoryOnLoop = () => {
    console.log("Function called when component");
    console.log("requesting", requestBody);

    axios
      .post(getHistoryUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("data", response.data);
        var data = response.data.length;
        console.log("datalenght", data);
        const olddata = localStorage.getItem("olddatalength");
        if (olddata != data) {
          localStorage.setItem("olddatalength", data);
          // alert("New Vital history is added")
          toast(
            "Your new Vital Signs Readings has been captured. You can now click Refresh to view the latest Readings.",
            {
              onClose: () => {
                // Callback when toast is closed
                closeModal1();
              },
            }
          );
          setShouldContinueInterval(true); // Stop the interval if condition is met
        }
      })

      .catch((error) => {
        console.error("Error:1111", error);
      });
  };

  useEffect(() => {
    if (okButtonClicked && shouldContinueInterval) {
      // Set up the interval
      const interval = setInterval(() => {
        if (shouldContinueInterval) {
          // Check the condition before calling the function
          callHistoryOnLoop();
        }
      }, 5000);

      // Cleanup on unmount or when dependencies change
      return () => clearInterval(interval);
    }
  }, [okButtonClicked, shouldContinueInterval]);

  const callVitalsHistoryOnLoad = () => {
    console.log("Function called when component is loaded");
    console.log("requesting", requestBody);

    axios
      .post(getHistoryUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("data", response.data);
        var data = response.data.length;
        localStorage.setItem("olddatalength", data);
        var dataArray = response.data;
        console.log("array lenth", dataArray.length);

        // else {
        if (dataArray.length !== 0) {
          if (!userCameFromCameraVitals) {
            setDateH(dataArray[0].onlyDate);
            setTimeH(dataArray[0].onlyTime);
            setBloodp(dataArray[0].bloodPressure);
            setOxyzen(dataArray[0].oxygenLevel);
            setHR(dataArray[0].heartRate);
            setTemp(dataArray[0].bodyTemprature);
            setResp(dataArray[0].respirationRate);
            setBMI(dataArray[0].bodyMassIndex);
            setPr(dataArray[0].printerval);
            setQrs(dataArray[0].qrsinterval);
            setQt(dataArray[0].qtinterval);
            setSt(dataArray[0].stinterval);
          }
        } else {
          setAge("0");
          setHeight("0");
          setWeight("0");
          setBloodp("0");
          setOxyzen("0");
          setHR("0");
          setTemp("0");
          setResp("0");
          setBMI("0");
          setPr("0");
          setQrs("0");
          setQt("0");
          setSt("0");
          setshowElectrodiagram(false);
          setAlertModel0(true);
        }
        // }

        // setVitalSigns(response.data);
      })
      .catch((error) => {
        console.log("respone  his code", error.response.status);
        if (error.response.status === 404) {
          callVitalsHistoryOnLoad();
        }

        console.error("Error:1111", error);
        setBloodp("0");
        setOxyzen("0");
        setHR("0");
        setTemp("0");
        setResp("0");
        setBMI("0");
        setPr("0");
        setQrs("0");
        setQt("0");
        setSt("0");
        setshowElectrodiagram(false);
        // setAlertModel0(true);
      });
  };

  var requestBodySave = {
    PatientGUID: guid,
    vitalSignType: "All",
    heartRate: parseInt(HR),
    bloodPressure: Blood1,
    respirationRate: parseInt(Respiration),
    oxygenLevel: parseInt(oxygen),
    bodyTemprature: parseFloat(Temperature),
    recordingDateTime: time,
    bodyMassIndex: bmi,
    qtinterval: qt,
    stinterval: ST,
    printerval: Pr,
    qrsinterval: Qrs,
  };
  const openLogoutModel = () => {
    setIsLogout(true);
  };

  // const connection = new signalR.HubConnectionBuilder().withUrl(`https://staycured-clinic-staging.azurewebsites.net/API/notificationHub`).build();

  const saveVitals = () => {
    setMustSave(false);
    setIsLoading(true);
    console.log("Requesting....", requestBodySave);
    axios
      .post(saveVitalsUrl, requestBodySave, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // Handle the response data here
        console.log("data", response.data);
        var data = response.data.result;
        // var errorjson = JSON.parse();
        if (data == "1") {
          console.log("vitals saved");
          setShowSaveButton(false);
          setRetakeVitalsButton(true);
          setIsSaveYes(true);
        } else {
          console.log("vitals not saved");
          setIsSaveNo(true);
        }
        // connection.start().then(() => {
        //   console.log('Connected!');
        // }).catch(err => console.error(err));
        // connection.invoke("SendNotification", "Vitals saved!").catch(err => console.error(err));
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [showGraph, setGraph] = useState(false);

  const heartbeat = parseInt(HR);
  const x1 = 60 / heartbeat;
  const y1 = 1.0 + parseFloat(qt) * parseFloat(Qrs);
  // Given x and y data
  const data = [
    { x: x1 * 0, y: y1 * 0 },
    { x: x1 * 0.1, y: y1 * 0.05 },
    { x: x1 * 0.12, y: y1 * 0.1 },
    { x: x1 * 0.2, y: y1 * 0.05 },
    { x: x1 * 0.21, y: y1 * 0 },
    { x: x1 * 0.26, y: y1 * 1 },
    { x: x1 * 0.31, y: y1 * -0.18 },
    { x: x1 * 0.35, y: y1 * 0 },
    { x: x1 * 0.4, y: y1 * 0 },
    { x: x1 * 0.45, y: y1 * 0.05 },
    { x: x1 * 0.5, y: y1 * 0.06 },
    { x: x1 * 0.53, y: y1 * 0.07 },
    { x: x1 * 0.58, y: y1 * 0.06 },
    { x: x1 * 0.6, y: y1 * 0.05 },
    { x: x1 * 0.65, y: y1 * 0 },
    { x: x1 * 0.68, y: y1 * 0 },
    { x: x1 * 0.7, y: y1 * 0 },
    { x: x1 * 0.75, y: y1 * 0 },
    { x: x1 * 0.78, y: y1 * 0.01 },
    { x: x1 * 0.8, y: y1 * 0.02 },
    { x: x1 * 0.82, y: y1 * 0.03 },
    { x: x1 * 0.85, y: y1 * 0.02 },
    { x: x1 * 0.86, y: y1 * 0.01 },
    { x: x1 * 0.88, y: y1 * 0 },
    { x: x1 * 0.9, y: y1 * 0 },
    { x: x1 * 0.9, y: y1 * 0 },
    { x: x1 * 0.92, y: y1 * 0 },
    { x: x1 * 0.95, y: y1 * 0 },
    { x: x1 * 1, y: y1 * 0.1 },
    { x: x1 * 1.1, y: y1 * 0.05 },
    { x: x1 * 1.15, y: y1 * 0.1 },
    { x: x1 * 1.16, y: y1 * 0.05 },
    { x: x1 * 1.17, y: y1 * 0 },
    { x: x1 * 1.25, y: y1 * 1 },
    { x: x1 * 1.3, y: y1 * -0.18 },
    { x: x1 * 1.4, y: y1 * 0 },
    { x: x1 * 1.55, y: y1 * 0 },
    { x: x1 * 1.65, y: y1 * 0.05 },
    { x: x1 * 1.71, y: y1 * 0.06 },
    { x: x1 * 1.75, y: y1 * 0.07 },
    { x: x1 * 1.78, y: y1 * 0.06 },
    { x: x1 * 1.79, y: y1 * 0.05 },
    { x: x1 * 1.8, y: y1 * 0.0 },
    { x: x1 * 2, y: y1 * 0.0 },
    { x: x1 * 2.1, y: y1 * 0.05 },
    { x: x1 * 2.12, y: y1 * 0.1 },
    { x: x1 * 2.2, y: y1 * 0.05 },
    { x: x1 * 2.21, y: y1 * 0 },
    { x: x1 * 2.26, y: y1 * 1 },
    { x: x1 * 2.31, y: y1 * -0.18 },
    { x: x1 * 2.35, y: y1 * 0 },
    { x: x1 * 2.4, y: y1 * 0 },
    { x: x1 * 2.45, y: y1 * 0.05 },
    { x: x1 * 2.5, y: y1 * 0.06 },
    { x: x1 * 2.53, y: y1 * 0.07 },
    { x: x1 * 2.58, y: y1 * 0.06 },
    { x: x1 * 2.6, y: y1 * 0.05 },
    { x: x1 * 2.65, y: y1 * 0 },
    { x: x1 * 2.68, y: y1 * 0 },
    { x: x1 * 2.7, y: y1 * 0 },
    { x: x1 * 2.75, y: y1 * 0 },
    { x: x1 * 2.78, y: y1 * 0.01 },
    { x: x1 * 2.8, y: y1 * 0.02 },
    { x: x1 * 2.82, y: y1 * 0.03 },
    { x: x1 * 2.85, y: y1 * 0.02 },
    { x: x1 * 2.86, y: y1 * 0.01 },
    { x: x1 * 2.88, y: y1 * 0 },
    { x: x1 * 2.9, y: y1 * 0 },
    { x: x1 * 2.9, y: y1 * 0 },
    { x: x1 * 2.92, y: y1 * 0 },
    { x: x1 * 2.95, y: y1 * 0 },
    { x: x1 * 3, y: y1 * 0.1 },
    { x: x1 * 3.1, y: y1 * 0.05 },
    { x: x1 * 3.15, y: y1 * 0.1 },
    { x: x1 * 3.16, y: y1 * 0.05 },
    { x: x1 * 3.17, y: y1 * 0 },
    { x: x1 * 3.25, y: y1 * 1 },
    { x: x1 * 3.3, y: y1 * -0.18 },
    { x: x1 * 3.4, y: y1 * 0 },
    { x: x1 * 3.55, y: y1 * 0 },
    { x: x1 * 3.65, y: y1 * 0.05 },
    { x: x1 * 3.71, y: y1 * 0.06 },
    { x: x1 * 3.75, y: y1 * 0.07 },
    { x: x1 * 3.78, y: y1 * 0.06 },
    { x: x1 * 3.79, y: y1 * 0.05 },
    { x: x1 * 3.8, y: y1 * 0.0 },
    { x: x1 * 4, y: y1 * 0.1 },
    { x: x1 * 4.1, y: y1 * 0.05 },
    { x: x1 * 4.15, y: y1 * 0.1 },
    { x: x1 * 4.16, y: y1 * 0.05 },
    { x: x1 * 4.17, y: y1 * 0 },
    { x: x1 * 4.25, y: y1 * 1 },
    { x: x1 * 4.3, y: y1 * -0.18 },
    { x: x1 * 4.4, y: y1 * 0 },
    { x: x1 * 4.55, y: y1 * 0 },
    { x: x1 * 4.65, y: y1 * 0.05 },
    { x: x1 * 4.71, y: y1 * 0.06 },
    { x: x1 * 4.75, y: y1 * 0.07 },
    { x: x1 * 4.78, y: y1 * 0.06 },
    { x: x1 * 4.79, y: y1 * 0.05 },
    { x: x1 * 4.8, y: y1 * 0.0 },
    { x: x1 * 5, y: y1 * 0.1 },
    { x: x1 * 5.1, y: y1 * 0.05 },
    { x: x1 * 5.15, y: y1 * 0.1 },
    { x: x1 * 5.16, y: y1 * 0.05 },
    { x: x1 * 5.17, y: y1 * 0 },
    { x: x1 * 5.25, y: y1 * 1 },
    { x: x1 * 5.3, y: y1 * -0.18 },
    { x: x1 * 5.4, y: y1 * 0 },
    { x: x1 * 5.55, y: y1 * 0 },
    { x: x1 * 5.65, y: y1 * 0.05 },
    { x: x1 * 5.71, y: y1 * 0.06 },
    { x: x1 * 5.75, y: y1 * 0.07 },
    { x: x1 * 5.78, y: y1 * 0.06 },
    { x: x1 * 5.79, y: y1 * 0.05 },
    { x: x1 * 5.8, y: y1 * 0.0 },
    { x: x1 * 6, y: y1 * 0.0 },
  ];

  const config = {
    // displayModeBar: false, // Set to false to hide the mode bar
    modeBarButtonsToRemove: ["plotlylogo"],
  };

  const layout = {
    title: "Electrocardiogram",
    xaxis: {
      title: "Time (s)",
      showgrid: true,
      zeroline: true,
      range: [0, x1 * 2],
      dtick: 0.25,
    },
    yaxis: {
      title: "(Milli-Volts)",
      showgrid: true,
      zeroline: true,
      range: [-0.5, 2],
      dtick: 0.25,
    },

    margin: {
      l: 50, // left margin
      r: 50, // right margin
      b: 50, // bottom margin
      t: 50, // top margin
      pad: 10, // padding around the plot area
    },
    width: 480, // Set the width of the chart in pixels
    height: 348, // Set the height of the chart in pixels
  };

  const trace = {
    x: data.map((point) => point.x),
    y: data.map((point) => point.y),
    type: "scatter",
    mode: "lines",
    name: "ECG Waveform",
    line: { shape: "linear" },
    marker: { color: "Navy" },
    hoverinfo: "none",
  };

  // useEffect(() => {
  //   const userCameFromCameraVitals = localStorage.getItem('cameFromCameraVitals');

  //   if (userCameFromCameraVitals) {

  //     localStorage.removeItem('cameFromCameraVitals');
  //   }
  // }, []);

  // const overlayStyle = {
  //   display: isModalOpen ? 'block' : 'none',
  //   position: 'fixed',
  //   top: 0,
  //   left: 0,
  //   width: '100%',
  //   height: '122%',
  //   backgroundColor: 'rgba(0, 0, 0, 0.5)',
  //   zIndex: 1000,
  // };

  const saveYes = {
    display: isSaveYes ? "block" : "none",
  };

  const saveNo = {
    display: isSaveNo ? "block" : "none",
    position: "fixed",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "navy",
    zIndex: 1000,
    width: "20em",
    height: "8.5em",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "10px",
    textAlign: "center",
    background: `linear-gradient(to bottom, navy 3.3em, white 3.3em)`,
  };

  const logout = {
    display: isLogout ? "block" : "none",
  };

  const okayButtonStyle = {
    backgroundColor: "navy",
    color: "white",
    fontWeight: "bold",
    position: "absolute",
    bottom: "10px",
    left: "8em",
    transform: "translateX(-50%)",
    cursor: "pointer",
    padding: "10px 20px",
    borderRadius: "5px",
  };

  const okayButtonStyleSuccess = {
    backgroundColor: "navy",
    color: "white",
    fontWeight: "bold",
    position: "absolute",
    bottom: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    cursor: "pointer",
    padding: "10px 20px",
    borderRadius: "5px",
  };

  const cancelButtonStyle = {
    backgroundColor: "navy",
    color: "white",
    fontWeight: "bold",
    position: "absolute",
    bottom: "10px",
    right: "2em",
    transform: "translateX(-50%)",
    cursor: "pointer",
    padding: "10px 20px",
    borderRadius: "5px",
  };

  const cancelButtonStyle1 = {
    backgroundColor: "navy",
    color: "white",
    fontWeight: "bold",
    position: "absolute",
    bottom: "10px",
    // right: '2em',
    transform: "translateX(-50%)",
    cursor: "pointer",
    padding: "10px 40px",
    borderRadius: "5px",
  };

  const headingStyle = {
    color: "white",
    marginTop: "-10px",
  };

  const alertTextStyle = {
    // marginTop: '1em',
    color: "black",
    marginTop: "8%",
  };

  const alertTextStyle1 = {
    // marginTop: '1em',
    color: "black",
    marginTop: "13%",
  };

  const historyBoldTextStyle = {
    fontWeight: "bold",
  };

  const Info = [
    "Blood pressure is the force exerted by circulating blood against the walls of the arteries.",
    "Oxygen saturation is a measure of the percentage of hemoglobin in the blood that is bound to oxygen.",
    "Heart rate is the number of times the heart beats per minute, measuring the rhythm of the cardiac cycle.",
    "Body temperature is the measure of the internal heat level of the human body.",
    "Respiration rate refers to the number of breaths taken per minute.",
    "Body Mass Index (BMI) is a measure of body fat based on a person's weight and height.",
    "QT interval represents the duration of ventricular depolarization and repolarization.",
    "ST segment is the portion of the ECG between the QRS complex and the T wave, reflecting the early part of ventricular repolarization.",
    "PR interval measures the time from the onset of atrial depolarization (P wave) to the onset of ventricular depolarization (QRS complex).",
    "QRS interval represents the time it takes for ventricular depolarization to occur.",
  ];

  const Info1 = [
    "120/80 - 140/90 mmHg",
    "95% to 100%",
    "60 - 90 bpm",
    "96 - 98.4 F",
    "12 - 20 BRPM",
    "18.5 - 25",
    "0.35 - 0.46 Sec",
    "0.08 - 0.12 Sec",
    "0.12 - 0.20 Sec",
    "0.06 - 0.10 Sec",
  ];

  const Info2 = [
    Blood1 + " mmHg",
    oxygen + "%",
    HR + " bpm",
    Temperature + "F",
    Respiration + " BRPM",
    bmi,
    qt + " Sec",
    ST + " Sec",
    Pr + " Sec",
    Qrs + " Sec",
  ];

  const InfoName = [
    "Blood Pressure",
    "Oxygen Saturation",
    "Heart Rate",
    "Body Temperature",
    "Respiration Rate",
    "Body Mass Index (BMI)",
    "QT Interval",
    "ST Segment",
    "PR Interval",
    "QRS Interval",
  ];
  var loginrequestBody = {
    username: userPhoneNumber,
    passwords: password,
  };

  const login = () => {
    setIsLoading(true);
    console.log("requesting...", loginrequestBody);
    axios
      .post(loginUrl, loginrequestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // Handle the response data here
        console.log("data", response.data);
        var data = response.data.result;
        // var errorjson = JSON.parse();
        if (data == "1") {
          console.log("success=", response.data.response);
          // localStorage.setItem('guid', response.data.guid);
          localStorage.setItem("password", password);
          setGender(response.data.gender);
          setHeight(response.data.height);
          setWeight(response.data.weight);
          setAge(response.data.about);
          setweighttype(
            response.data.weighttype === "" ? "KG" : response.data.weighttype
          );
          setheighttype(
            response.data.heighttype === "" ? "CM" : response.data.heighttype
          );
          setinches(response.data.inches);
          setfeet(response.data.feet);

          localStorage.setItem("gender", response.data.gender);
          localStorage.setItem("height", response.data.height);
          localStorage.setItem("weight", response.data.weight);
          localStorage.setItem("about", response.data.about);
          localStorage.setItem(
            "weighttype",
            response.data.weighttype === "" ? "KG" : response.data.weighttype
          );
          localStorage.setItem(
            "heighttype",
            response.data.heighttype === "" ? "CM" : response.data.heighttype
          );
          localStorage.setItem("inches", response.data.inches);
          localStorage.setItem("feet", response.data.feet);
        } else {
          console.log("error=", response.data.errormessage);
        }
      })
      .catch((error) => {
        // Handle any errors here

        if (error.response.status === 404) {
          login();
        }
        console.error("Error:1111", error);
      })
      .finally(() => {
        setIsLoading(false); // Turn off the loading spinner

        if (modalIsOpen1) {
          if (updateFlag == false) {
            setUpdateModalOpen(false);
          } else {
            setUpdateModalOpen(true);
          }
          setModalIsOpen1(false);
        }

        if (notMobileFlag == false) {
          setModalIsOpen(false);
          setIsModalOpen(true);
        } else {
          setIsModalOpen(false);
        }
      });
  };

  useEffect(() => {
    // Check for phone number in local storage
    const savedPhoneNumber = localStorage.getItem("phoneNumberInput");
    if (savedPhoneNumber) {
      setPhoneNumber(savedPhoneNumber);
    }
  }, []);

  const handleCountryCodeChange = (country) => {
    const code = country.dialCode;
    setCountryCode(code);
    setPhoneNumber("");
  };

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const LoadingSpinner = () => {
    return (
      <div className="homePage-loading-spinner">
        <div className="homePage-spinner"></div>
      </div>
    );
  };

  const SaveMust = {
    display: mustSave ? "block" : "none",
  };

  const notsaveoverlayStyle = {
    display: mustSave ? "block" : "none",
  };

  const okayButtonStyleSuccess1 = {
    backgroundColor: "navy",
    color: "white",
    fontWeight: "bold",
    position: "absolute",
    bottom: "10px",
    left: "30%",
    transform: "translateX(-50%)",
    cursor: "pointer",
    padding: "10px 30px",
    borderRadius: "5px",
  };

  return (
    <div>
      <header
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          padding: "18px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(Indian-Girls.jpg)`,
          zIndex: "997",
        }}
      >
        <img
          src="yourvitals_logo_panner.png"
          alt="yourVitals"
          style={{ width: "300px", height: "100px", marginRight: "1.5em" }}
        />
      </header>
      <div className="homePageContainer">
        <div
          style={{
            height: "1.9em",
            // weight: '2.5em',
            padding: "9px",
            display: "flex",
            backgroundColor: "navy",
            border: "1px solid #ccc",
            borderRadius: "5px",
            justifyContent: "space-between",
            alignItems: "center",
            color: "white",
            marginBottom: "3px",
          }}
        >
          <div
            style={{
              marginLeft: "9.3em",
              textAlign: "center",
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            VITAL SIGNS
          </div>

          <button
            style={{
              backgroundColor: "#000080",
              color: "white",
              border: "none",
              padding: "10px",
              cursor: "pointer",
              borderRadius: "10px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center", // Center the content vertically
              height: 18,
            }}
            onClick={() => {
              openLogoutModel();
            }}
          >
            <img
              src="logout.png"
              alt="Back"
              style={{
                height: "25px",
                width: "25px",
              }}
            />
            {/* Logout */}
          </button>
        </div>

        <div className="bioHeader">
          {/* <div style={{
            marginLeft: '9.3em',
            textAlign: 'right',
            fontSize: '20px',
            fontWeight: 300
          }}>
            VITAL SIGNS
          </div> */}
          <div className="bioheadergrid">
            <div className="bioheaderLeft-item">
              {/* <p> */}
              <span style={historyBoldTextStyle}>Gender:</span> {gender}
              {/* </p> */}
            </div>
            <div className="bioheaderLeft-item">
              {/* <p> */}
              <span style={historyBoldTextStyle}>Height:</span>{" "}
              {heighttype === "CM" ? height + " cm" : feet + "'" + inches + '"'}
              {/* </p> */}
            </div>
            <div className="bioheaderRight-item">
              {/* <p> */}
              <span style={historyBoldTextStyle}>Age:</span> {age}
              {/* </p> */}
            </div>

            <div className="bioheaderRight-item">
              {/* <p> */}
              <span style={historyBoldTextStyle}>Weight:</span>{" "}
              {weighttype == "KG" ? weight + " kg" : weight + " lb"}
              {/* </p> */}
            </div>

            <div className="bioheaderLeft-item">
              <span style={historyBoldTextStyle}>Date:</span> {DateH}
            </div>

            <div className="bioheaderRight-item">
              <span style={historyBoldTextStyle}>Time:</span> {TimeH}
            </div>
          </div>
        </div>

        <div
          style={logoutoverlayStyle}
          className="logoutoverlayStyle"
          onClick={() => setIsLogout(false)}
        ></div>

        {isPopupOpen && (
          <div>
            {" "}
            <div className="homePageOverlay"></div>
          </div>
        )}
        {isECGPopupOpen && (
          <div>
            {" "}
            <div className="homePageOverlay"></div>
          </div>
        )}
        {isECGPopupOpen1 && (
          <div>
            {" "}
            <div className="homePageOverlay"></div>
          </div>
        )}

        {/* <!-- Grid container --> */}
        <div class="grid-container">
          {/* <!-- Grid items --> */}
          <div class="grid-item">
            <div className="homePage-image-container">
              <img
                src="blood-pressure.png"
                alt="Blood Pressure"
                className="bloodPressureImage"
              />
            </div>
            <div className="homePage-header-container">
              <h4 className="homePageHeader-title">Blood Pressure</h4>
            </div>
            <div className="homePage-details-container">
              {Blood1 !== 0 ? (
                <>
                  <div className="value">
                    {Blood1} mmHg
                    <div className="ibutton">
                      <button
                        className="instructionButton"
                        onClick={() => {
                          setCurrentInfo(Info[0]);
                          setCurrentInfo1(Info1[0]);
                          setCurrentInfo2(Info2[0]);
                          setCurrentInfoName(InfoName[0]);
                          setIsPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="value">
                  {" "}
                  0{" "}
                  <div className="ibutton">
                    <button
                      className="instructionButton"
                      onClick={() => {
                        setCurrentInfo(Info[0]);
                        setCurrentInfo1(Info1[0]);
                        setCurrentInfo2(Info2[0]);
                        setCurrentInfoName(InfoName[0]);
                        setIsPopupOpen(true);
                      }}
                    >
                      ?
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="homePage-title-contaniner">
              <div className="tilte">Normal Range</div>
            </div>
            <div className="homePage-range-container">
              <div className="range">120/80 - 140/90 mmHg</div>
            </div>
          </div>
          <div class="grid-item">
            <div className="homePage-image-container">
              <img
                src="oxygen.png"
                alt="Oxygen Saturation"
                className="image1"
              />
            </div>
            <div className="homePage-header-container">
              <h4 className="homePageHeader-titleoxy">Oxygen Saturation</h4>
            </div>
            <div className="homePage-details-container">
              {oxygen !== 0 ? (
                <>
                  <div className="value">
                    {oxygen} %
                    <div className="ibutton">
                      <button
                        className="instructionButton"
                        onClick={() => {
                          setCurrentInfo(Info[1]);
                          setCurrentInfo1(Info1[1]);
                          setCurrentInfo2(Info2[1]);
                          setCurrentInfoName(InfoName[1]);
                          setIsPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="value">
                  {" "}
                  0
                  <div className="ibutton">
                    <button
                      className="instructionButton"
                      onClick={() => {
                        setCurrentInfo(Info[1]);
                        setCurrentInfo1(Info1[1]);
                        setCurrentInfo2(Info2[1]);
                        setCurrentInfoName(InfoName[1]);
                        setIsPopupOpen(true);
                      }}
                    >
                      ?
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="homePage-title-contaniner">
              <div className="tilte">Normal Range</div>
            </div>
            <div className="homePage-range-container">
              <div className="range">95 - 100 %</div>
            </div>
          </div>

          <div class="grid-item">
            <div className="homePage-image-container">
              <img src="heart.png" alt="Heart Rate" className="image" />
            </div>
            <div className="homePage-header-container1 ">
              <h4 className="homePageHeader-title1">Heart Rate</h4>
            </div>
            <div className="homePage-details-container">
              {HR !== 0 ? (
                <>
                  <div className="value">
                    {HR} bpm
                    <div className="ibutton">
                      <button
                        className="instructionButton"
                        onClick={() => {
                          setCurrentInfo(Info[2]);
                          setCurrentInfo1(Info1[2]);
                          setCurrentInfo2(Info2[2]);
                          setCurrentInfoName(InfoName[2]);
                          setIsPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="value">
                  0
                  <div className="ibutton">
                    <button
                      className="instructionButton"
                      onClick={() => {
                        setCurrentInfo(Info[2]);
                        setCurrentInfo1(Info1[2]);
                        setCurrentInfo2(Info2[2]);
                        setCurrentInfoName(InfoName[2]);
                        setIsPopupOpen(true);
                      }}
                    >
                      ?
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="homePage-title-contaniner">
              <div className="tilte">Normal Range</div>
            </div>
            <div className="homePage-range-container1">
              <div className="range1">60 - 90 bpm</div>
            </div>
          </div>
          <div class="grid-item">
            <div className="homePage-image-container">
              <img
                src="Thermometer.png"
                alt="Body Temperature"
                className="image"
              />
            </div>

            <div className="homePage-header-container ">
              <h4 className="homePageHeader-titleBT">Body Temperature</h4>
            </div>

            <div className="homePage-details-container">
              {Temperature !== 0 ? (
                <>
                  <div className="value">
                    {Temperature} F
                    <div className="ibutton">
                      <button
                        className="instructionButton"
                        onClick={() => {
                          setCurrentInfo(Info[3]);
                          setCurrentInfo1(Info1[3]);
                          setCurrentInfo2(Info2[3]);
                          setCurrentInfoName(InfoName[3]);
                          setIsECGPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="value">
                  0
                  <div className="ibutton">
                    <button
                      className="instructionButton"
                      onClick={() => {
                        setCurrentInfo(Info[3]);
                        setCurrentInfo1(Info1[3]);
                        setCurrentInfo2(Info2[3]);
                        setCurrentInfoName(InfoName[3]);
                        setIsPopupOpen(true);
                      }}
                    >
                      ?
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="homePage-title-contaniner">
              <div className="tilte">Normal Range</div>
            </div>

            <div className="homePage-range-container1">
              <div className="range1">96 - 98.4 F</div>
            </div>
          </div>
          <div class="grid-item">
            <div className="homePage-image-container">
              <img src="lungs.png" alt="Respiration Rate" className="image" />
            </div>

            <div className="homePage-header-container">
              <h4 className="homePageHeader-title">Respiration Rate</h4>
            </div>

            <div className="homePage-details-container">
              {Respiration !== 0 ? (
                <>
                  <div className="value">
                    {Respiration} BRPM
                    <div className="ibutton">
                      <button
                        className="instructionButton"
                        onClick={() => {
                          setCurrentInfo(Info[4]);
                          setCurrentInfo1(Info1[4]);
                          setCurrentInfo2(Info2[4]);
                          setCurrentInfoName(InfoName[4]);
                          setIsECGPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div>
                  </div>
                  {/* <div className="result">{setRespriationValue}</div> */}
                </>
              ) : (
                <div className="value">
                  {" "}
                  0
                  <div className="ibutton">
                    <button
                      className="instructionButton"
                      onClick={() => {
                        setCurrentInfo(Info[4]);
                        setCurrentInfo1(Info1[4]);
                        setCurrentInfo2(Info2[4]);
                        setCurrentInfoName(InfoName[4]);
                        setIsPopupOpen(true);
                      }}
                    >
                      ?
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="homePage-title-contaniner">
              <div className="tilte">Normal Range</div>
            </div>

            <div className="homePage-range-container2">
              <div className="range2">12 - 20 BRPM</div>
            </div>
          </div>
          <div class="grid-item">
            <div className="homePage-image-container">
              <img src="bmi.png" alt="Body Mass Index" className="image" />
            </div>

            <div className="homePage-header-container">
              <h4 className="homePageHeader-title">Body Mass Index</h4>
            </div>

            <div className="homePage-details-container">
              {bmi !== 0 ? (
                <>
                  {/* <div className="value">{bmi.toFixed(2)}</div>  */}
                  <div className="value">
                    {bmi}
                    <div className="ibutton">
                      <button
                        className="instructionButton"
                        onClick={() => {
                          setCurrentInfo(Info[5]);
                          setCurrentInfo1(Info1[5]);
                          setCurrentInfo2(Info2[5]);
                          setCurrentInfoName(InfoName[5]);
                          setIsECGPopupOpen(true);
                        }}
                      >
                        ?
                      </button>
                    </div>
                  </div>
                  {/* <div className="result">{setBmiValue}</div> */}
                </>
              ) : (
                <div className="value">
                  {" "}
                  0{" "}
                  <div className="ibutton">
                    <button
                      className="instructionButton"
                      onClick={() => {
                        setCurrentInfo(Info[5]);
                        setCurrentInfo1(Info1[5]);
                        setCurrentInfo2(Info2[5]);
                        setCurrentInfoName(InfoName[5]);
                        setIsPopupOpen(true);
                      }}
                    >
                      ?
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="homePage-title-contaniner">
              <div className="tilte">Normal Range</div>
            </div>

            <div className="homePage-range-container2">
              <div className="range2">18.5 - 25</div>
            </div>
          </div>
        </div>

        <div
          className="homePageFooter"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {showElectrodiagram && (
            <button
              className="homePageElctrocardiogram-button"
              onClick={toggleECGContainer}
            >
              <span className="homePageButton-text">
                {showContainer ? "Electrocardiogram" : "Electrocardiogram"}
              </span>
              <img src="arrow.png" alt="Arrow" style={imgStyle} />
            </button>
          )}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeDialog}
            contentLabel="Take Vital Signs"
            className="homePageTakeVitalsModal"
            overlayClassName="homePageOverlay"
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "-30px",
                }}
              ></div>
              <form>
                <div
                  id="dialog_box"
                  style={{ width: "100%", background: "url('dialog_box.jpg')" }}
                >
                  <div
                    id="header"
                    style={{
                      width: "100%",
                      height: "40px",
                      background: "navy",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "1.5em",
                    }}
                  >
                    <div>
                      <h2
                        style={{
                          // margin: "0",
                          color: "white",
                          fontSize: "13px",
                          fontWeight: "bold",
                          display: "flex",
                          marginLeft: "2em",
                          // alignItems: "center",
                          // justifyContent: "center",
                        }}
                      >
                        Confirm and / or Edit your profile
                      </h2>
                    </div>

                    <button
                      onClick={() => {
                        setModalIsOpen(false);
                        login();
                      }}
                      style={{
                        // height: '20px',
                        // width: '20px',
                        // right:'2px',
                        background: "navy",
                        color: "white",
                        borderColor: "white",
                        fontWeight: "bold",
                        padding: "3px 7px",
                        borderRadius: "14px",
                        fontSize: "14px",
                        display: "flex",
                        marginLeft: "1em",
                        // marginRight: '2px',
                        // marginTop: '1em'
                      }}
                    >
                      x
                    </button>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      background: "white",
                    }}
                  ></div>
                  <div
                    id="profile_section"
                    style={{ margin: "7px", textAlign: "center" }}
                  >
                    <label className="labelFonts">Mobile Phone Number</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            src="phone.png"
                            style={{
                              height: "35px",
                              width: "35px",
                              marginLeft: "1px",
                              marginRight: "5px",
                              borderRadius: "5px",
                            }}
                          />
                          <PhoneInput
                            disabled={true}
                            country={countryCode}
                            onChange={handleCountryCodeChange}
                            inputStyle={{
                              width: "6.3em",
                              pointerEvents: "none",
                              backgroundColor: "#D3D3D3",
                            }}
                            containerStyle={{ textAlign: "left" }}
                            countryCodeEditable={false}
                            // Add readOnly a comment indicating that the country dropdown is read-only
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          pointerEvents: "none",
                        }}
                      >
                        <input
                          type="tel"
                          id="newField"
                          name="newField"
                          value={userName}
                          style={{
                            width: "10.5em",
                            height: "32px",
                            marginLeft: "0.5em",
                            border: 0,
                            borderRadius: "4px",
                            border: "1px solid #D3D3D3",
                            backgroundColor: "#D3D3D3",
                          }}
                        />
                      </div>
                    </div>

                    {/* karthi */}

                    <div style={{ width: "255px", marginBottom: "20px" }}>
                      <div
                        style={{
                          position: "relative",
                          marginRight: "-28px",
                          marginTop: "1em",
                        }}
                      >
                        <label className="labelFonts">Password</label>
                        <div style={{ display: "flex" }}>
                          <img
                            src="password.png"
                            style={{
                              height: "35px",
                              width: "35px",
                              marginTop: "1px",
                              marginLeft: "1px",
                              marginRight: "5px",
                              borderRadius: "5px",
                            }}
                          />
                          <input
                            type={showPassword ? "text" : "password"}
                            // id="password"
                            name="password"
                            value={password}
                            style={{
                              width: "260px",
                              padding: "6px",
                              height: "25px",
                              border: "none",
                              borderRadius: "4px",
                              pointerEvents: "none",
                              border: "1px solid #D3D3D3",
                              backgroundColor: "#D3D3D3",
                            }}
                          />
                          <button
                            onClick={togglePasswordVisibility}
                            disabled={isLoading}
                            style={{
                              position: "absolute",
                              top: "65%",
                              right: "0.3em",
                              transform: "translateY(-50%)",
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={showPassword ? faEye : faEyeSlash}
                              style={{ fontSize: "20px" }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>

                    <h4
                      style={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {/* Gender */}
                    </h4>
                    <label className="labelFonts">Gender</label>
                    <div style={{ display: "flex" }}>
                      <img
                        src="gender.png"
                        style={{
                          height: "35px",
                          width: "35px",
                          marginLeft: "1px",
                          marginRight: "5px",
                          borderRadius: "5px",
                        }}
                      />
                      <select
                        id="edtgender"
                        value={gender}
                        style={{
                          width: "100%",
                          height: "34px",
                          // marginBottom: "5px",
                          textAlign: "center",
                        }}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="" disabled>
                          Select the Gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <h4
                      style={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {/* Age */}
                    </h4>
                    <label className="ageFont">Age</label>
                    <div style={{ display: "flex" }}>
                      <img
                        src="age.png"
                        style={{
                          height: "35px",
                          width: "35px",
                          marginLeft: "1px",
                          marginRight: "5px",
                          borderRadius: "5px",
                        }}
                      />
                      <input
                        id="edtage"
                        type="number"
                        style={{
                          width: "235px",
                          height: "34px",
                          marginBottom: "5px",
                          textAlign: "center",
                        }}
                        placeholder="Enter the Age"
                        value={age}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue >= 0 && inputValue <= 120) {
                            setAge(inputValue);
                          }
                        }}
                        onKeyPress={(e) => {
                          // Check if the pressed key is a number (0-9) or a control key (e.g., Backspace)
                          const isNumericInput = /^[0-9]+$/.test(e.key);

                          // If the input is not numeric, prevent it from being entered
                          if (!isNumericInput) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>

                    {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                    {/* <div
                      style={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    > */}

                    {/* <label
                        className="switch"
                        style={{
                          marginTop: "7px",
                          marginLeft: "80px",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={heighttype === "FT/IN"}
                          onChange={toggleUnit}
                        />
                        <span
                          className="slider"
                          style={
                            heighttype === "FT/IN"
                              ? {
                                textAlign: "start",
                                paddingLeft: "3px",
                                paddingTop: "8px",
                                fontSize: "8px",
                              }
                              : {
                                textAlign: "end",
                                paddingRight: "3px",
                                paddingTop: "8px",
                                fontSize: "8px",
                              }
                          }
                        >
                        {heighttype === "FT/IN" ? "FT/IN  " : "  CM"}
                        </span>

                      </label> */}

                    {/* </div> */}
                    <label className="labelFonts2">Height</label>
                    <div style={{ display: "flex" }}>
                      <img
                        src="height.png"
                        style={{
                          height: "37px",
                          width: "35px",
                          marginTop: "2px",
                          marginLeft: "2px",
                          marginRight: "4px",
                          marginBottom: "10px",
                          borderRadius: "5px",
                        }}
                      />

                      {heighttype === "CM" ? (
                        <input
                          id="edtheight"
                          type="number"
                          style={{
                            width: "46%",
                            height: "34px",
                            marginBottom: "5px",
                            textAlign: "center",
                          }}
                          placeholder="Enter the Height in Centimeters"
                          value={height}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue >= 0 && inputValue <= 272) {
                              setHeight(inputValue);
                            }
                          }}
                        />
                      ) : (
                        <div style={{ display: "flex", flexDirection: "rows" }}>
                          <input
                            type="number"
                            style={{
                              width: "50%",
                              height: "34px",
                              textAlign: "center",
                            }}
                            value={feet}
                            onChange={(e) =>
                              setfeet(
                                Math.max(
                                  0,
                                  Math.min(8, parseInt(e.target.value))
                                )
                              )
                            }
                            placeholder="Feet"
                          />
                          <input
                            type="number"
                            style={{
                              width: "50%",
                              height: "34px",
                              textAlign: "center",
                            }}
                            value={inches}
                            onChange={(e) =>
                              setinches(
                                Math.max(
                                  0,
                                  Math.min(
                                    11,
                                    parseInt(e.target.value || 0, 10)
                                  )
                                )
                              )
                            }
                            placeholder="Inches"
                          />
                        </div>
                      )}
                      <label
                        style={{
                          marginTop: "1em",
                          // marginRight:"-5em",
                          // marginLeft:"3.5em",
                          fontSize: "0.8em",
                          fontWeight: "bold",
                          marginLeft: "0.3em",
                        }}
                      >
                        {" "}
                        CM
                      </label>

                      <label
                        className="switch"
                        style={{
                          marginTop: "5px",
                          marginLeft: "12.6em",
                          border: "2px solid black",
                          borderRadius: "15px",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={heighttype === "FT/IN"}
                          onChange={toggleUnit}
                        />

                        <span
                          className="slider"
                          style={
                            heighttype === "FT/IN"
                              ? {
                                  textAlign: "start",
                                  paddingLeft: "3px",
                                  paddingTop: "8px",
                                  fontSize: "8px",
                                }
                              : {
                                  textAlign: "end",
                                  paddingRight: "3px",
                                  paddingTop: "8px",
                                  fontSize: "8px",
                                }
                          }
                        ></span>
                      </label>

                      <label
                        style={{
                          marginTop: "1em",
                          // marginRight:"-5em"
                          marginLeft: "4.5em",
                          fontSize: "0.8em",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        FT/IN
                      </label>
                    </div>

                    <h4
                      style={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {/* <label
                        className="switch"
                        style={{
                          marginTop: "7px",
                          marginLeft: "80px",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={weighttype === "LB"}
                          onChange={toggleWeightUnit}
                        />
                        <span
                          className="slider"
                          style={
                            weighttype === "LB"
                              ? {
                                textAlign: "start",
                                paddingLeft: "3px",
                                paddingTop: "8px",
                                fontSize: "8px",
                              }
                              : {
                                textAlign: "end",
                                paddingRight: "3px",
                                paddingTop: "8px",
                                fontSize: "8px",
                              }
                          }
                        >
                         </span>
                      </label> */}
                    </h4>
                    <label className="labelFonts2">Weight</label>
                    <div style={{ display: "flex" }}>
                      <img
                        src="weight.png"
                        style={{
                          height: "35px",
                          width: "35px",
                          marginTop: "2px",
                          marginLeft: "3px",
                          marginRight: "4px",
                          marginBottom: "10px",
                          borderRadius: "5px",
                        }}
                      />

                      <input
                        id="edtweight"
                        type="number"
                        style={{
                          width: "44%",
                          height: "34px",
                          marginBottom: "5px",
                          textAlign: "center",
                        }}
                        placeholder={
                          weighttype === "KG"
                            ? "Enter the Weight in Kilograms"
                            : "Enter the Weight in Pounds"
                        }
                        value={weight}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue >= 0 &&
                            (weighttype === "KG"
                              ? inputValue <= 272
                              : inputValue <= 600)
                          ) {
                            setWeight(inputValue);
                          }
                        }}
                        onKeyPress={(e) => {
                          const isNumericInput = /^[0-9]+$/.test(e.key);
                          if (!isNumericInput) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <label
                        style={{
                          marginTop: "1em",
                          // marginRight:"-5em",
                          // marginLeft:"3.5em",
                          fontSize: "0.8em",
                          fontWeight: "bold",
                          marginLeft: "0.3em",
                        }}
                      >
                        {" "}
                        KG
                      </label>
                      <label
                        className="switch"
                        style={{
                          marginTop: "5px",
                          marginLeft: "12.6em",
                          border: "2px solid black",
                          borderRadius: "15px",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={weighttype === "LB"}
                          onChange={toggleWeightUnit}
                        />
                        <span
                          className="slider"
                          style={
                            weighttype === "LB"
                              ? {
                                  textAlign: "start",
                                  paddingLeft: "3px",
                                  paddingTop: "8px",
                                  fontSize: "8px",
                                }
                              : {
                                  textAlign: "end",
                                  paddingRight: "3px",
                                  paddingTop: "8px",
                                  fontSize: "8px",
                                }
                          }
                        ></span>
                      </label>
                      <label
                        style={{
                          marginTop: "1em",
                          // marginRight:"-5em"
                          marginLeft: "4.5em",
                          fontSize: "0.8em",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        LB
                      </label>
                    </div>
                  </div>
                  <div
                    style={{ width: "100%", height: "1px", background: "grey" }}
                  ></div>
                  <div
                    id="proceed_section"
                    style={{
                      textAlign: "center",
                      margin: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <p style={{ color: "black", fontWeight: "bold" }}>
                      If necessary, you can change your profile before
                      proceeding.
                    </p>
                    <button
                      type="button"
                      className="homePageButton"
                      style={{
                        backgroundColor: "#f8b413",
                        color: "white",
                        fontWeight: "bold",
                        padding: "5px 20px",
                        fontSize: "15px",
                        margin: "5px",
                      }}
                      onClick={handleStartClick}
                    >
                      Proceed
                    </button>
                    {validationError && (
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        Please fill all required fields.
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </Modal>

          <Modal
            isOpen={modalIsOpen1}
            onRequestClose={closeDialog1}
            contentLabel="Take Vital Signs"
            className="homePageTakeVitalsModal"
            overlayClassName="homePageOverlay"
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "-30px",
                }}
              ></div>
              <form>
                <div
                  id="dialog_box"
                  style={{ width: "100%", background: "url('dialog_box.jpg')" }}
                >
                  <div
                    id="header"
                    style={{
                      width: "100%",
                      height: "40px",
                      background: "navy",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "1.5em",
                    }}
                  >
                    <div>
                      <h2
                        style={{
                          // margin: "0",
                          color: "white",
                          fontSize: "13px",
                          fontWeight: "bold",
                          display: "flex",
                          marginLeft: "9em",
                          // alignItems: "center",
                          // justifyContent: "center",
                        }}
                      >
                        My Profile
                      </h2>
                    </div>
                    <button
                      className="close-button"
                      onClick={() => {
                        updateFlag = false;
                        setModalIsOpen1(false);
                        login();
                      }}
                      style={{
                        // height: '20px',
                        // width: '20px',
                        // right:'2px',
                        background: "navy",
                        color: "white",
                        borderColor: "white",
                        fontWeight: "bold",
                        padding: "3px 7px",
                        borderRadius: "14px",
                        fontSize: "14px",
                        display: "flex",
                        marginLeft: "6em",
                        // marginRight: '2px',
                        // marginTop: '1em'
                      }}
                    >
                      x
                    </button>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      background: "white",
                    }}
                  ></div>
                  <div
                    id="profile_section"
                    style={{ margin: "7px", textAlign: "center" }}
                  >
                    <label className="labelFonts">Mobile Phone Number</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            src="phone.png"
                            style={{
                              height: "35px",
                              width: "35px",
                              marginLeft: "1px",
                              marginRight: "5px",
                              borderRadius: "5px",
                            }}
                          />
                          <PhoneInput
                            disabled={true}
                            country={countryCode}
                            onChange={handleCountryCodeChange}
                            inputStyle={{
                              width: "6.3em",
                              pointerEvents: "none",
                              backgroundColor: "#D3D3D3",
                            }}
                            containerStyle={{ textAlign: "left" }}
                            countryCodeEditable={false}
                            // Add readOnly a comment indicating that the country dropdown is read-only
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          pointerEvents: "none",
                        }}
                      >
                        <input
                          type="tel"
                          // id="newField"
                          name="newField"
                          value={userName}
                          style={{
                            width: "10.5em",
                            height: "32px",
                            marginLeft: "0.5em",
                            border: 0,
                            borderRadius: "4px",
                            border: "1px solid #D3D3D3",
                            backgroundColor: "#D3D3D3",
                          }}
                        />
                      </div>
                    </div>

                    {/* karthi */}

                    <div style={{ width: "255px", marginBottom: "20px" }}>
                      <div
                        style={{
                          position: "relative",
                          marginRight: "-28px",
                          marginTop: "1em",
                        }}
                      >
                        <label className="labelFonts">Password</label>
                        <div style={{ display: "flex" }}>
                          <img
                            src="password.png"
                            style={{
                              height: "35px",
                              width: "35px",
                              marginTop: "1px",
                              marginLeft: "1px",
                              marginRight: "5px",
                              borderRadius: "5px",
                            }}
                          />
                         <input
        type={showPassword ? 'text' : 'password'}
        name="password"
        value={password}
        onChange={handleInputChange}
        style={{
          width: '260px',
          padding: '6px',
          height: '25px',
          border: 'none',
          borderRadius: '4px',
          border: '1px solid #D3D3D3',
          backgroundColor: '#D3D3D3',
        }}
        disabled={!showPassword || !isEditing}
      />
      {showPassword ? (
        <FontAwesomeIcon
          icon={faEdit}
          style={{
            fontSize: '20px',
            position: 'absolute',
            top: '65%',
            right: '0.3em',
            transform: 'translateY(-50%)',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={handleEditClick}
        />
      ) : (
        <button
          onClick={togglePasswordVisibility}
          style={{
            position: 'absolute',
            top: '65%',
            right: '0.3em',
            transform: 'translateY(-50%)',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <FontAwesomeIcon icon={faEyeSlash} style={{ fontSize: '20px' }} />
        </button>
      )}
                        </div>
                      </div>
                    </div>
                    <label className="labelFonts">Gender</label>
                    <div style={{ display: "flex" }}>
                      <img
                        src="gender.png"
                        style={{
                          height: "35px",
                          width: "35px",
                          marginRight: "5px",
                          borderRadius: "5px",
                        }}
                      />

                      {/* <h4
                   style={{
                     color: "black",
                     fontSize: "14px",
                     fontWeight: "bold",
                   }}
                 >
                   Gender
                 </h4> */}
                      <select
                        id="edtgender"
                        value={gender}
                        style={{
                          width: "244px",
                          height: "34px",
                          marginBottom: "15px",
                          textAlign: "center",
                        }}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="" disabled>
                          Select the Gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <label className="ageFont">Age</label>
                    <div style={{ display: "flex" }}>
                      <img
                        src="age.png"
                        style={{
                          height: "35px",
                          width: "35px",
                          marginTop: "3.5px",
                          marginLeft: "1px",
                          marginRight: "5px",
                          borderRadius: "5px",
                        }}
                      />
                      {/* <h4
                   style={{
                     color: "black",
                     fontSize: "14px",
                     fontWeight: "bold",
                   }}
                 >
                   Age
                 </h4> */}
                      <input
                        id="edtage"
                        type="number"
                        style={{
                          width: "235px",
                          height: "34px",
                          marginBottom: "5px",
                          textAlign: "center",
                        }}
                        placeholder="Enter the Age"
                        value={age}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue >= 0 && inputValue <= 120) {
                            setAge(inputValue);
                          }
                        }}
                        onKeyPress={(e) => {
                          // Check if the pressed key is a number (0-9) or a control key (e.g., Backspace)
                          const isNumericInput = /^[0-9]+$/.test(e.key);

                          // If the input is not numeric, prevent it from being entered
                          if (!isNumericInput) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>

                    {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                    <label className="labelFonts2">Height</label>
                    <div style={{ display: "flex" }}>
                      <img
                        src="height.png"
                        style={{
                          height: "37px",
                          width: "35px",
                          marginTop: "2px",
                          marginLeft: "2px",
                          marginRight: "4px",
                          marginBottom: "10px",
                          borderRadius: "5px",
                        }}
                      />

                      {heighttype === "CM" ? (
                        <input
                          id="edtheight"
                          type="number"
                          style={{
                            width: "46%",
                            height: "34px",
                            marginBottom: "5px",
                            textAlign: "center",
                          }}
                          placeholder="Enter the Height in Centimeters"
                          value={height}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue >= 0 && inputValue <= 272) {
                              setHeight(inputValue);
                            }
                          }}
                        />
                      ) : (
                        <div style={{ display: "flex", flexDirection: "rows" }}>
                          <input
                            type="number"
                            style={{
                              width: "50%",
                              height: "34px",
                              textAlign: "center",
                            }}
                            value={feet}
                            onChange={(e) =>
                              setfeet(
                                Math.max(
                                  0,
                                  Math.min(8, parseInt(e.target.value))
                                )
                              )
                            }
                            placeholder="Feet"
                          />
                          <input
                            type="number"
                            style={{
                              width: "50%",
                              height: "34px",
                              textAlign: "center",
                            }}
                            value={inches}
                            onChange={(e) =>
                              setinches(
                                Math.max(
                                  0,
                                  Math.min(
                                    11,
                                    parseInt(e.target.value || 0, 10)
                                  )
                                )
                              )
                            }
                            placeholder="Inches"
                          />
                        </div>
                      )}
                      <label
                        style={{
                          marginTop: "1em",
                          // marginRight:"-5em",
                          // marginLeft:"3.5em",
                          fontSize: "0.8em",
                          fontWeight: "bold",
                          marginLeft: "0.3em",
                        }}
                      >
                        {" "}
                        CM
                      </label>

                      <label
                        className="switch"
                        style={{
                          marginTop: "5px",
                          marginLeft: "12.6em",
                          border: "2px solid black",
                          borderRadius: "15px",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={heighttype === "FT/IN"}
                          onChange={toggleUnit}
                        />

                        <span
                          className="slider"
                          style={
                            heighttype === "FT/IN"
                              ? {
                                  textAlign: "start",
                                  paddingLeft: "3px",
                                  paddingTop: "8px",
                                  fontSize: "8px",
                                }
                              : {
                                  textAlign: "end",
                                  paddingRight: "3px",
                                  paddingTop: "8px",
                                  fontSize: "8px",
                                }
                          }
                        ></span>
                      </label>

                      <label
                        style={{
                          marginTop: "1em",
                          // marginRight:"-5em"
                          marginLeft: "4.5em",
                          fontSize: "0.8em",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        FT/IN
                      </label>
                    </div>

                    <h4
                      style={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {/* <label
                        className="switch"
                        style={{
                          marginTop: "7px",
                          marginLeft: "80px",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={weighttype === "LB"}
                          onChange={toggleWeightUnit}
                        />
                        <span
                          className="slider"
                          style={
                            weighttype === "LB"
                              ? {
                                textAlign: "start",
                                paddingLeft: "3px",
                                paddingTop: "8px",
                                fontSize: "8px",
                              }
                              : {
                                textAlign: "end",
                                paddingRight: "3px",
                                paddingTop: "8px",
                                fontSize: "8px",
                              }
                          }
                        >
                         </span>
                      </label> */}
                    </h4>
                    <label className="labelFonts2">Weight</label>
                    <div style={{ display: "flex" }}>
                      <img
                        src="weight.png"
                        style={{
                          height: "35px",
                          width: "35px",
                          marginTop: "2px",
                          marginLeft: "3px",
                          marginRight: "4px",
                          marginBottom: "10px",
                          borderRadius: "5px",
                        }}
                      />

                      <input
                        id="edtweight"
                        type="number"
                        style={{
                          width: "44%",
                          height: "34px",
                          marginBottom: "5px",
                          textAlign: "center",
                        }}
                        placeholder={
                          weighttype === "KG"
                            ? "Enter the Weight in Kilograms"
                            : "Enter the Weight in Pounds"
                        }
                        value={weight}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue >= 0 &&
                            (weighttype === "KG"
                              ? inputValue <= 272
                              : inputValue <= 600)
                          ) {
                            setWeight(inputValue);
                          }
                        }}
                        onKeyPress={(e) => {
                          const isNumericInput = /^[0-9]+$/.test(e.key);
                          if (!isNumericInput) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <label
                        style={{
                          marginTop: "1em",
                          // marginRight:"-5em",
                          // marginLeft:"3.5em",
                          fontSize: "0.8em",
                          fontWeight: "bold",
                          marginLeft: "0.3em",
                        }}
                      >
                        {" "}
                        KG
                      </label>
                      <label
                        className="switch"
                        style={{
                          marginTop: "5px",
                          marginLeft: "12.6em",
                          border: "2px solid black",
                          borderRadius: "15px",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={weighttype === "LB"}
                          onChange={toggleWeightUnit}
                        />
                        <span
                          className="slider"
                          style={
                            weighttype === "LB"
                              ? {
                                  textAlign: "start",
                                  paddingLeft: "3px",
                                  paddingTop: "8px",
                                  fontSize: "8px",
                                }
                              : {
                                  textAlign: "end",
                                  paddingRight: "3px",
                                  paddingTop: "8px",
                                  fontSize: "8px",
                                }
                          }
                        ></span>
                      </label>
                      <label
                        style={{
                          marginTop: "1em",
                          // marginRight:"-5em"
                          marginLeft: "4.5em",
                          fontSize: "0.8em",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        LB
                      </label>
                    </div>
                  </div>
                  <div
                    id="proceed_section"
                    style={{
                      textAlign: "center",
                      margin: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <p style={{ color: "black", fontWeight: "bold" }}>
                      If necessary, you can change your profile before
                      proceeding.
                    </p>
                    <button
                      type="button"
                      className="homePageButton"
                      style={{
                        backgroundColor: "#f8b413",
                        color: "white",
                        fontWeight: "bold",
                        padding: "5px 20px",
                        fontSize: "15px",
                        margin: "5px",
                      }}
                      onClick={validateForm}
                    >
                      Update
                    </button>
                    {validationError && (
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        Please fill all required fields.
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </Modal>
          {showContainer && (
            <div
              className="white-container"
              style={{
                marginBottom: "10px",
                width: "96%",
                background: "#e5e5e6;",
                position: "relative",
              }}
            >
              <div
                style={HomepageECGContainer}
                className="HomepageECGContainer"
              >
                {/* Electrocardiogram graph */}
                <Plot
                  data={[trace]}
                  layout={layout}
                  config={{ displaylogo: false }}
                />
              </div>

              <div>
                <center>
                  <h3 className="homePageECGIntervelText">ECG Intervals</h3>
                </center>

                <div className="ECGgrid-container">
                  {/* <div className="homePageContainer-image"> */}
                  <div class="grid-item">
                    {/* <div className="homePageContainer-image"> */}
                    <div className="homePage-header-container">
                      <h3 className="homePageHeader-title2">QT Interval</h3>
                    </div>
                    <div className="homePage-details-container">
                      <div className="value">
                        {" "}
                        {qt} sec{" "}
                        <div className="ibutton">
                          <button
                            className="instructionButton"
                            onClick={() => {
                              setCurrentInfo(Info[6]);
                              setCurrentInfo1(Info1[6]);
                              setCurrentInfo2(Info2[6]);
                              setCurrentInfoName(InfoName[6]);
                              setIsECGPopupOpen(true);
                            }}
                          >
                            ?
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="homePage-title-contaniner">
                      <div className="tilteT">Normal Range</div>
                    </div>
                    <div className="homePage-range-container1">
                      <div className="range5">0.35 - 0.46 Sec</div>
                    </div>
                  </div>
                  <div class="grid-item">
                    <div className="homePage-header-container">
                      <h3 className="homePageHeader-title2">ST Segment</h3>
                    </div>
                    <div className="homePage-details-container">
                      <div className="value">
                        {" "}
                        {ST} sec{" "}
                        <div className="ibutton">
                          <button
                            className="instructionButton"
                            onClick={() => {
                              setCurrentInfo(Info[7]);
                              setCurrentInfo1(Info1[7]);
                              setCurrentInfo2(Info2[7]);
                              setCurrentInfoName(InfoName[7]);
                              setIsECGPopupOpen1(true);
                            }}
                          >
                            ?
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="homePage-title-contaniner">
                      <div className="tilteT">Normal Range</div>
                    </div>
                    <div className="homePage-range-container1">
                      <div className="range5">0.08 - 0.12 Sec</div>
                    </div>
                  </div>

                  <div class="grid-item">
                    <div className="homePage-header-container">
                      <h3 className="homePageHeader-title2">PR Interval</h3>
                    </div>
                    <div className="homePage-details-container">
                      <div className="value">
                        {" "}
                        {Pr} sec{" "}
                        <div className="ibutton">
                          <button
                            className="instructionButton"
                            onClick={() => {
                              setCurrentInfo(Info[8]);
                              setCurrentInfo1(Info1[8]);
                              setCurrentInfo2(Info2[8]);
                              setCurrentInfoName(InfoName[8]);
                              setIsECGPopupOpen1(true);
                            }}
                          >
                            ?
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="homePage-title-contaniner">
                      <div className="tilteT">Normal Range</div>
                    </div>
                    <div className="homePage-range-container1">
                      <div className="range6">0.12 - 0.20 Sec</div>
                    </div>
                  </div>

                  <div class="grid-item">
                    <div className="homePage-header-container">
                      <h3 className="homePageHeader-title2">QRS Interval</h3>
                    </div>
                    <div className="homePage-details-container">
                      <div className="value">
                        {" "}
                        {Qrs} sec
                        <div className="ibutton">
                          <button
                            className="instructionButton"
                            onClick={() => {
                              setCurrentInfo(Info[9]);
                              setCurrentInfo1(Info1[9]);
                              setCurrentInfo2(Info2[9]);
                              setCurrentInfoName(InfoName[9]);
                              setIsECGPopupOpen(true);
                            }}
                          >
                            ?
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="homePage-title-contaniner">
                      <div className="tilteT">Normal Range</div>
                    </div>
                    <div className="homePage-range-container1">
                      <div className="range6">0.06 - 0.10 Sec</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
              <div
                style={{
                  position: "absolute",
                  top: "23em",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                {/* <button
                  className="zoomPlusButton"
                  onClick={() => {
                    zoomECG(true); // Zoom In
                    handleScrollX(scrollX * zoomFactor);
                    handleScrollY(scrollY * zoomFactor);
                  }}
                >
                  +
                </button>
                <div className="zoomText">Zoom</div>
                <button
                  className="zoomMinusButton"
                  onClick={() => {
                    zoomECG(false); // Zoom Out
                    handleScrollX(scrollX / zoomFactor);
                    handleScrollY(scrollY / zoomFactor);
                  }}
                >
                  -
                </button> */}
              </div>
            </div>
          )}
          <button className="homePage-ecg-button" onClick={profileButton}>
            Profile
          </button>

          {RetakeVitalsButton && (
            <button className="homePage-ecg-button" onClick={takeVitalSigns}>
              Take Vital Signs
            </button>
          )}

          {showSaveButton && (
            <button className="homePage-ecg-button" onClick={takeVitalSigns}>
              Retake Vital Signs
            </button>
          )}

          <button className="homePage-ecg-button" onClick={GoToHistory}>
            Vital Signs History
          </button>
          {showSaveButton && (
            <button
              className="homePage-ecg-button"
              onClick={saveVitals}
              disabled={isLoading}
            >
              Save
            </button>
          )}

          <div
            style={saveoverlayStyle}
            className="saveoverlayStyle"
            onClick={() => setIsSaveYes(false)}
          ></div>

          <div
            style={notsaveoverlayStyle}
            className="notsaveoverlayStyle"
            onClick={() => setMustSave(false)}
          ></div>

          <div overlayClassName="homePageOverlay">
            <div style={saveNo}>
              <button style={okayButtonStyleSuccess} onClick={okClick}>
                Ok
              </button>
              <h2 style={headingStyle}>Alert</h2>
              <h4 style={alertTextStyle}>
                Network issue please take vitlas once again.
              </h4>
            </div>
          </div>
        </div>

        <div
          style={grayUpdateOverlayStyle}
          className="grayUpdateOverlayStyle"
        ></div>
        <div
          style={overlayStyle}
          className="overlayStyle"
          onClick={() => setIsModalOpen(false)}
        ></div>
        <div
          style={overlayStyle1}
          className="overlayStyle"
          onClick={() => setIsModalOpen1(false)}
        ></div>

        {/* <div onClick={() => setAlertModel(true)} style={logoutoverlayStyle} ></div>
        {AlertModel && (
          <div style={modalStyle2}>
            <h2 style={headingStyle}>Alert</h2>
            <h4 style={alertTextStyle}>Welcome to Your Vitals.<br></br>You are new user, so your history is empty. Please set a profile and take your Vital Signs. </h4>
            <button style={cancelButtonStyle1}
              onClick={() => setAlertModel(false)}
            >
              OK
            </button>
          </div>
        )} */}

        <div
          onClick={() => setAlertModel(true)}
          style={grayOverlayStyle}
          className="grayOverlayStyle"
        ></div>
        <div
          onClick={() => setAlertModel0(true)}
          style={grayOverlayStyle0}
          className="grayOverlayStyle0"
        ></div>
      </div>

      {/*__________________Alert Modal fixed to screen size_____________________________*/}

      <ToastContainer className="toaster" />

      <div style={SaveMust} className="SaveMust">
        <button style={okayButtonStyleSuccess1} onClick={saveVitals}>
          Save
        </button>
        <button style={cancelButtonStyle} onClick={GoHistory}>
          Cancel
        </button>
        <h2 style={headingStyle}>Alert</h2>
        <h4 style={alertTextStyle1}>Vitals data not saved.</h4>
      </div>

      <div style={saveYes} className="saveYes">
        <button style={okayButtonStyleSuccess} onClick={okClick}>
          Ok
        </button>
        <h2 style={headingStyle}>Alert</h2>
        <h4 style={alertTextStyle1}>Vitals data saved successfully.</h4>
      </div>
      <div style={modalStyle1} className="modalStyle1">
        <button style={RefreshButtonStyle} onClick={closeModal1}>
          Refresh
        </button>
        <button
          style={cancelButtonStyle}
          onClick={() => {
            closeModal12(false);
            setIsModalOpen(false);
          }}
        >
          Cancel
        </button>
        <h2 style={headingStyle}>Alert</h2>
        <h4 style={alertTextStyle}>
          After capturing you Vital Signs using your mobile phone, this web
          application will refresh with you updated Vitals.{" "}
        </h4>
      </div>
      <div style={openModalStyle} className="openModalStyle">
        <h2 style={headingStyle}>Alert</h2>
        <h4 style={alertTextStyle}>Profile Updated Successfully.</h4>
        <button
          style={cancelButtonStyle1}
          onClick={() => setUpdateModalOpen(false)}
        >
          OK
        </button>
      </div>
      <div style={modalStyle} className="modalStyle">
        <button
          style={okButtonStyle}
          onClick={() => {
            setOkButtonClicked(true); // setting state to true when Ok button is clicked
            setIsModalOpen1(true);
            setIsModalOpen(false);
          }}
        >
          {" "}
          {/*setIsModalOpen1*/}
          Ok
        </button>
        <button style={cancelButtonStyle} onClick={() => setIsModalOpen(false)}>
          Cancel
        </button>
        <h2 style={headingStyle}>Alert</h2>
        <h4 style={alertTextStyle}>
          Your Vital Signs can only be captured using your Mobile Phone Camera.
          <br></br> <br></br>Please Logon to the YourVitals App using your
          Mobile Phone to capture your Vital Signs.
        </h4>
      </div>
      {AlertModel && (
        <div style={modalStyle2} className="modalStyle2">
          <h2 style={headingStyle}>Alert</h2>
          <h4 style={alertTextStyle}>
            Welcome to YourVitals.<br></br> <br></br>Please setup your profile and change your password.<br></br><br></br>Once your profile is set you can start measuring your vitalsigns.
          </h4>
          <button
            style={cancelButtonStyle1}
            onClick={() => {
              CloseModel();
            }}
          >
            OK
          </button>
        </div>
      )}
      <div style={logout} className="logout">
        <button
          style={okayButtonStyle}
          onClick={() => {
            backButton();
          }}
        >
          Logout
        </button>
        <button
          style={cancelButtonStyle}
          onClick={() => {
            setIsLogout(false);
          }}
        >
          Cancel
        </button>
        <h2 style={headingStyle}>Alert</h2>
        <h4 style={alertTextStyle1}>Are you sure? (Yes/No)</h4>
      </div>

      {isPopupOpen && (
        <div>
          {/* <div className="homePageOverlay"></div> */}
          <div className="popup">
            <div className="popupheader">
              <b>{currentInfoName}</b>
            </div>
            <div className="popup-content">
              <div className="instructionContent">
                {currentInfo}
                <br />
                <br />
                Normal Range: <b>{currentInfo1} </b>
                <br />
                <br />
                Your Reading: <b>{currentInfo2}</b>
              </div>
              <button className="instructionCloseButton" onClick={closePopup}>
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {isECGPopupOpen && (
        <div>
          {/* <div className="homePageOverlay"></div> */}
          <div className="ECGIntervalPopup">
            <div className="popupheader">
              <b>{currentInfoName}</b>
            </div>
            <div className="popup-content">
              <div className="instructionContent">
                {currentInfo}
                <br />
                <br />
                Normal Range: <b>{currentInfo1} </b>
                <br />
                <br />
                Your Reading: <b>{currentInfo2}</b>
              </div>
              <button className="instructionCloseButton" onClick={closePopup}>
                Ok
              </button>
            </div>
          </div>
        </div>
      )}

      {isECGPopupOpen1 && (
        <div>
          {/* <div className="homePageOverlay"></div> */}
          <div className="ECGIntervalPopup1">
            <div className="popupheader">
              <b>{currentInfoName}</b>
            </div>
            <div className="popup-content">
              <div className="instructionContent">
                {currentInfo}
                <br />
                <br />
                Normal Range: <b>{currentInfo1} </b>
                <br />
                <br />
                Your Reading: <b>{currentInfo2}</b>
              </div>
              <button className="instructionCloseButton" onClick={closePopup}>
                Ok
              </button>
            </div>
          </div>
        </div>
      )}

      {AlertModel0 && (
        <div style={modalStyle0} className="modalStyle0">
          <h2 style={headingStyle}>Alert</h2>
          <h4 style={alertTextStyle}>
          Please setup your profile and change your password.<br></br><br></br>Once your profile is set you can start measuring your vitalsigns.
          </h4>
          <button
            style={cancelButtonStyle1}
            onClick={() => setAlertModel0(false)}
          >
            OK
          </button>
        </div>
      )}

      <footer
        className="homePagefooter"
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "navy",
          // marginTop: '100vh',
          width: "100%",
          backgroundImage: `url(Indian-Girls.jpg)`,
        }}
      >
        {/* <div> */}
        <div
          style={{
            color: "orange",
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          YourVitals, Inc.{" "}
        </div>
        <div style={{ color: "#ffffff" }}>© 2023, All Rights Reserved.</div>

        <div style={{ alignItems: "center" }}>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open("https://yourvitals.ai/terms_of_use.html", "_blank");
            }}
          >
            Terms Of Use
          </button>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(
                "https://yourvitals.ai/privacy_policy.html",
                "_blank"
              );
            }}
          >
            Privacy Policy
          </button>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open("https://yourvitals.ai/#", "_blank");
            }}
          >
            FAQ
          </button>
        </div>
      </footer>
      {isLoading && <LoadingSpinner />}
    </div>
  );
}

export default HomePage;
