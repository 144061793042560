import React, { useEffect, useRef } from "react";
import Chart from 'chart.js/auto'

export default function LineChart({chartData}) {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const myChartRef = chartRef.current.getContext("2d");

        chartInstance.current = new Chart(myChartRef, {
            type: "line",
            data:{
                labels: chartData[0].labels,
                datasets: chartData.length == 2 ? [
                    {
                        label: chartData[0].label,
                        data: chartData[0].data
                    },
                    {
                        label: chartData[1].label,
                        data: chartData[1].data
                    }
                ]:
                [
                    {
                        label: chartData[0].label,
                        data: chartData[0].data
                    }
                ]
            }
        });

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };

    }, [chartData]);

    return (
        <div>
            <canvas ref={chartRef}
             style={{ width: "100%", height: "300px" }}
             />
        </div>
    )
}
