// web api

let proxyURL = 'https://cors-anywhere.herokuapp.com/'; //${proxyURL}

export const BASE_API_URL1 = 'https://staycured-clinic.azurewebsites.net/API/';   //live
export const BASE_API_URL = 'https://staycured-clinic-staging.azurewebsites.net/API/';   //staging

// export const BASE_API_URL1 = `${proxyURL}https://staycured-clinic.azurewebsites.net/API/`;   //live with proxy
// export const BASE_API_URL = `${proxyURL}https://staycured-clinic-staging.azurewebsites.net/API/`;  //staging with proxy



export function preventReload(){
    // Prompt custom message when reload or leave is triggered
   const handleBeforeUnload = (event) => {
       const message = "Are you sure you want to leave? Your unsaved changes may be lost.";
       event.returnValue = message; // Standard for most browsers
       return message; // For some older browsers
   };

   window.onbeforeunload = handleBeforeUnload;

   // Unmount the window.onbeforeunload event
   return () => {
       window.onbeforeunload = null;
   };
}